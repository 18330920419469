import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/userReducer";
import meta from "../reducers/metaReducer";
import organisations from "../reducers/organisationsReducer";
import products from "../reducers/productsReducer";
import bookings from "../reducers/bookingsReducer";

export default createStore(
    combineReducers({
        user,
        meta,
        organisations,
        products,
        bookings
    }),
    {},
    applyMiddleware(thunk)
)