import { Box, Button } from "@mui/material"
import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ProgressButton from "./progressButton";

const MicroActionButtonGroup = props => {

    const sx = {
      container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'flex-end'
      }
    }

    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }

    return (
      <React.Fragment>
        <Box sx={sx.container}>
        <Button onClick={handleClose} size="small" color="secondary"><CloseIcon /> {props.closeLabel || 'Cancel'}</Button>
        <ProgressButton waiting={props.waiting || false} onClick={props.onSubmit} size="small" color="secondary" variant="contained"><CheckIcon /> {props.submitLabel || 'OK'}</ProgressButton>
        </Box>
      </React.Fragment>
    )
}

export default MicroActionButtonGroup;