import { useTheme, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import globalContext from "../../context/globalContext"
import UploadImage from '../uploadImage';
import ProfileAvatar from "./profileAvatar";
import images from '../../assets/imgs/index';
import DeleteIcon from '@mui/icons-material/Delete';



const useStyles = makeStyles((theme) => ({
    avatar: {
        display: 'flex',
        height: 90,
        width: 90,
        backgroundColor: theme.palette.grey[200],
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    }
  }))

const ProfileUploadImage = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    

    const handleUploadSuccess = imageUrl => {
        console.log(imageUrl)
        if(imageUrl !== null)
            context.user.profile.updateProfile({photoURL: imageUrl})
            .then(() => {
                context.fetchUserProfile();
            })
            .catch((err) => {
                console.log(err)
            //... throw an error
            }); 
    }

    const removePhotoURL = () => {
        context.user.profile.updateProfile({photoURL: null})
        .then(() => {
            context.fetchUserProfile();
        })
        .catch((err) => {
            console.log(err)
        //... throw an error
        }); 
    }

    return (
        <div className="flex-col align-center pb">
            <ProfileAvatar size="140">
                <UploadImage
                    handleSuccess={handleUploadSuccess} 
                    handleDelete={null} 
                    docRef={context.user.profile.uid} 
                    childRef="user_images" 
                    imageUrl={context.user.profile.photoURL || images.avatar_default}
                    variant="avatar"
                    maxUploadSize={2000}
                    />
            </ProfileAvatar>
            <Button onClick={removePhotoURL} color="secondary" size="small">Remove Image</Button>
        </div>
    )
}

export default ProfileUploadImage