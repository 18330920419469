import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {connect} from 'react-redux';
import 'firebase/auth';

import {useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import { login } from '../actions/userActions';
import YupetoFullLoader from '../components/yupetoFullLoader';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 1100,
    gap: 60,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    paddingBottom: 60,
    paddingTop: 60,
  }
}))


const Home = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const [waiting, setWaiting] = useState(true);

  useEffect(() => {

    setWaiting((props.user.status === 'loading'));

    if(props.user.status !== 'in' && props.user.status !== 'loading')
      history.push('/signin');
    
    if(props.user.status === 'in' && props.user.status !== 'loading')
      history.push('/business/account');

  }, [props.user])
  

  return (
    <React.Fragment>
      <Helmet>
        <title>Yupeto</title>
        <meta name="description" content="Pet services booking system." />
      </Helmet>

      {waiting && <YupetoFullLoader />}

    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (fields) => {
            dispatch(login(fields));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
