import { v4 as uuidv4 } from 'uuid';

export function comparator (a, b, orderBy, order = 'desc') {
    var x, y;

    if(Array.isArray(orderBy)){

        orderBy.map(z => {
            x = a[z]!=null?a[z]:'';
            y = b[z]!=null?b[z]:'';

            if(isNaN(x) === false)
                x = Number(x);

            if(isNaN(y) === false)
                y = Number(x);

            if(x !== y){
                if (y < x) return order == 'desc' ? -1 : 1;
                if (y > x) return order == 'desc' ? 1 : -1;
            }
        })

    } else {

        x = a[orderBy]!=null?a[orderBy]:'';
        y = b[orderBy]!=null?b[orderBy]:'';

        if (y < x) return order == 'desc' ? -1 : 1;
        if (y > x) return order == 'desc' ? 1 : -1;

    }
    return 0;
}


export function multiSort (data = [], orderBy = []){
    //
    //  Order by needs to be an array of objects with orderBy and order properties
    //  e.g. [{orderBy: id, order'desc'}, {orderBy: name, order'asc'}]
    //
    //  Initially reverse the order becuase you want to sort 
    //  from the last first so that the first property will
    //  become the main sorting factor 
    var revOrder = JSON.parse(JSON.stringify(orderBy)).reverse();

    // Make a deep copy of the data
    var arr = JSON.parse(JSON.stringify(data));

    // Loop through the properties to order by
    revOrder.map(o => {

        // Sort the data by that property
        arr = arr.sort((a, b) => {
            return comparator(a, b, o.orderBy, o.order)
        })
    })

    return arr;
}

// Makes the columns name more presentable
export function cleanHeader(header){
    let newHeader = header;
    if(header.indexOf('id_') > -1)
        newHeader = newHeader.replace('id_', '') + ' ID';

    newHeader = newHeader.replace(/_/gi, ' ');
    return newHeader.charAt(0).toUpperCase() + newHeader.slice(1)
}

export function formatAddress(address, commas = true){
    if(typeof address !== 'undefined'){

        const keys = [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'locality',
            'province',
            'postal_code'
        ];
        let fa = '';
        keys.forEach(k => {
            if(address[k] !== '' && address[k] !== null)
                fa += address[k] + (commas ? ', ' : ' ');
        })
        if(fa.length > 0)
            fa = fa.slice(0, (fa.length - 2));
        return fa;

    } else
        return '';
}

export function firestoreDate(date){

    if(typeof date === 'string')
        return new Date(date)

    if(typeof date.toDate === 'function')
        return new Date(date.toDate())

    if(typeof date.seconds !== 'undefined')
        return new Date(date.seconds*1000)

    if(typeof date.getFullYear === 'function')
        return new Date(date)

    return null;
}


export function localeDate(date, options = {}){

    const toDate = firestoreDate(date);
    if(toDate === null)
        return null;

    return toDate.toLocaleDateString(navigator.language, options)
}

export function localeDateFromUnix(date){
    const toDate = new Date(date*1000);
    if(toDate === null)
        return null;

    return toDate.toLocaleDateString(navigator.language)
}

export const subscriptionLevels = {
    PRO: {
      label: 'Pro',
      image: 'account_pro'
    },
    FREE: {
      label: 'Free',
      image: 'account_standard'
    }
}

export const cardType = (type) => {
    switch(type){
        case 'amex': 
            return {
                label: 'AMEX',
                image: 'card_amex'
            }
        case 'diners': 
            return {
                label: 'Diners',
                image: 'card_any'
            }
        case 'discover': 
            return {
                label: 'Discover',
                image: 'card_any'
            }
        case 'jcb': 
            return {
                label: 'JCB',
                image: 'card_any'
            }
        case 'mastercard': 
            return {
                label: 'Mastercard',
                image: 'card_mastercard'
            }
        case 'unionpay': 
            return {
                label: 'Union Pay',
                image: 'card_any'
            }
        case 'visa': 
            return {
                label: 'Visa',
                image: 'card_visa'
            }
        default: 
            return {
                label: 'Unknown',
                image: 'card_any'
            }
    }
}


export function parseNewLines(str){

    return str.split("\n").map((value, index) => {
        return (
            <span key={index}>
            {value}
            <br />
            </span>
        )   
    })

}

export const month = (mnth, idxZero = 0) => {
    const short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if(idxZero > 0)
        mnth = +(mnth)-idxZero;
    return short[mnth];
} 

export const longMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


export function generateShortId() {
    const id = uuidv4().replaceAll('-', '').toUpperCase().slice(0, 8);
    return id;
}

export const getFirstOfTheMonth = () => {
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    return today;
}

export const bookingsThisMonth = bookings => {
    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();

    return bookings.filter(booking => {
        const createdDate = firestoreDate(booking.created_on);
        return createdDate.getMonth() === thisMonth && createdDate.getFullYear() === thisYear;
    }).length;
}