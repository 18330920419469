  
const bookingsReducer = (state = {
    data: [],
    status: 'LOADING',
    msg: ''
}, action) => {
    switch(action.type){
        case "BOOKINGS_LOAD":
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'ERROR'?action.payload:'',
                data: action.status === 'OK'?action.payload:[]
            }
            break;
        default:
    }
    return state;
}

export default bookingsReducer;