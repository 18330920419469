import { Button, Box } from "@mui/material";
import React from "react"
import { generateShortId } from "../../../global/helpers";
import BusinessProductPricing from "./businessProductPricing";

const BusinessProductPricings = props => {

    const handleAddItem = () => {
        let newItems = JSON.parse(JSON.stringify(props.value));
        newItems.push({
            id: generateShortId(),
            name: `New Pricing ${props.value.length+1}`,
            currency: 'GBP',
            prices: []
        });
        if(typeof props.handleSelectionChange === 'function')
            props.handleSelectionChange(props.name, newItems);
    }

    const handleUpdateItem = (idx, val) => {
        let newItems = JSON.parse(JSON.stringify(props.value));
        newItems[idx] = val;
        if(typeof props.handleSelectionChange === 'function')
            props.handleSelectionChange(props.name, newItems);
    }

    const handleDeleteItem = (idx) => {
        let newItems = JSON.parse(JSON.stringify(props.value));
        newItems.splice(idx, 1)
        if(typeof props.handleSelectionChange === 'function')
            props.handleSelectionChange(props.name, newItems);
    }

    return (
        <React.Fragment>

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, pb: 2}}>
                {props.value.map((item, idx) => (
                    <BusinessProductPricing item={item} key={idx} idx={idx} handleUpdate={handleUpdateItem} handleDelete={handleDeleteItem}/>
                ))}
            </Box>

            <Button variant="contained" size="small" color="secondary" onClick={handleAddItem}>Create Pricing Model</Button>

        </React.Fragment>
    )
}

export default BusinessProductPricings