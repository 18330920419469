import { Box, Toolbar, Link, IconButton, Typography, useMediaQuery } from '@mui/material';
import React, {useContext, useState, useEffect} from 'react';
import BusinessMenu from './menu';
import MenuIcon from '@mui/icons-material/Menu'
import globalContext from '../../context/globalContext';
import YupetoFullLoader from '../../components/yupetoFullLoader';
import ProfileAvatar from '../profile/profileAvatar';
import { useHistory, useParams } from 'react-router-dom';


const drawerWidth = 320;

const BusinessLayout = props => {

    const context = useContext(globalContext);
    const history = useHistory();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [organisationName, setOrganisationName] = useState('')
    const {id} = useParams();
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    useEffect(() => {

        // When the organisation data changes and providing there are some organisations and the status is OK
        if(context.organisations.data.length > 0 && context.organisations.status === 'OK'){
    
          // Grab the organisation record with the id of the param
          const getOrganisation = context.organisations.data.find(o => o.id === id);
    
          // If the organisation has changed update the record
          if(typeof getOrganisation !== 'undefined' && organisationName !== getOrganisation.organisation_name)
            setOrganisationName(getOrganisation.organisation_name);
    
        }
    
      }, [context.organisations.data])

    return (
        <React.Fragment>

            {context.organisations.status === 'LOADING' && <YupetoFullLoader />}

            {context.organisations.status === 'OK' && 
            <Box sx={{ display: 'flex' }}>

                {/* Menu */}
                <BusinessMenu drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

                {/* Main */}
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
                >

                    <Toolbar sx={{pt: 1}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <IconButton onClick={handleDrawerToggle} sx={{ display: { lg: 'none' }, p: 0, pr: 2 }} color="secondary"><MenuIcon /></IconButton>
                                <Typography variant="h5">{organisationName}</Typography>
                            </Box>
                            <Link onClick={() => context.toggleMenuOpen(true)}>
                                <ProfileAvatar size="60" />
                            </Link>
                        </Box>
                    </Toolbar>
                    <Box sx={{ flexGrow: 1, pt: 3, pb: 3, pr: isSm ? 2 : 3, pl: isSm ? 2 : 3}}>
                        {props.children}
                    </Box>
                </Box>


            </Box>
            }

        </React.Fragment>
    )
}

export default BusinessLayout;