import { Collapse, useTheme, Alert } from "@mui/material"
import firebase from 'firebase/app';
import 'firebase/auth';
import React, {useState, useContext, useEffect} from "react";
import globalContext from "../../context/globalContext";
import InputField from '../../components/inputField';
import { makeValidation, validateForm } from '../../global/formHelper';
import Reauthorise from "../signin/reauthorise";
import MicroActionButtonGroup from "../../components/microActionButtonGroup";

const ProfileEditEmail = props => {

    const theme = useTheme();
    const context = useContext(globalContext);

    const [ reauthenticate, setReauthenticate ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ waiting, setWaiting ] = useState(false);

    const [form, setForm] = useState({
      email: context.user.profile.email || ''
    })
  
    const [validation, setValidation] = useState(makeValidation([
      {
        name: 'email',
        display_name: 'Email',
        required: true
      }
    ]));
    
    useEffect(() => {
        handleValidate();
    }, [form])

    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleValidate = () => {
      const newValidation = validateForm(form, validation);
      setValidation(newValidation);
    }
  
    const handleSubmit = () => {

      setError(null);
      setSuccess(false);
      setWaiting(true);
      
      if(form.email !== context.user.profile.email)
        firebase.auth().currentUser.updateEmail(form.email)
        .then(() => {

          if(typeof props.onSuccess === 'function')
            props.onSuccess('We have sent you an email to verify your new email address.');
          else
            setSuccess('We have sent you an email to verify your new email address.');

          setWaiting(false);
          context.updateProfile({email: form.email});
          firebase.auth().currentUser.sendEmailVerification();
        })
        .catch((err) => {
          console.log(err);
          //... throw an error
          setWaiting(false);
          if(typeof err.code !== 'undefined'){
            switch(err.code){
                case 'auth/requires-recent-login':
                  setReauthenticate(true);
                  break;
                default:
                  setError('Something went wrong');
            }
          }
          else
            setError('Something went wrong');
        }); 

    }

    const handleAuthorised = () => {
      setReauthenticate(false);
      handleSubmit();
    }

    const handleClose = () => {
      if(typeof props.onClose === 'function')
        props.onClose();
    }

    return (
      <React.Fragment>
        <div className="flex-col flex pb">
            {error !== null && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <InputField name='email' validation={validation.email} value={form.email} handleChange={handleInputChange}  autoFocus={true}/>
            <Collapse in={reauthenticate}><Reauthorise handleSuccess={handleAuthorised} onCancel={handleClose} /></Collapse>
            {!reauthenticate && <MicroActionButtonGroup onClose={handleClose} onSubmit={handleSubmit} waiting={waiting} />}

        </div>
      </React.Fragment>
    )
}

export default ProfileEditEmail;