export function productsLoad (payload) {
    return dispatch => {

        dispatch({
            status: 'OK',
            payload: payload,
            type: 'PRODUCTS_LOAD'
        })

    }
}
