import React from 'react';
import {Button, useTheme, CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: 10
  }
}))

const ProgressButton = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {waiting, variant, color, fullWidth, onClick, size, gutterBottom} = props;

  return (
    <Button 
      disabled={waiting || false} variant={variant || 'default'} 
      color={color || 'default'} 
      fullWidth={fullWidth || false} 
      onClick={onClick}
      size={size || 'medium'}
      classes={{
        root: gutterBottom === true ? classes.gutterBottom : {}
      }}
      >
        {!waiting && props.children}
        {waiting && <CircularProgress color='inherit' size={20} style={{margin: '0 10px'}}/>}
    </Button>
  );
}

export default ProgressButton;
