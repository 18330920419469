import React from 'react';
import {useTheme, Box} from '@mui/material';

const ArrowLabel = props => {

  const theme = useTheme();
  const direction = props.direction || 'right';
  const fill = props.color ? theme.palette[props.color].main : '#fdf7f7';
  const size = props.size || 20;

  return (
    <React.Fragment>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
        {direction.toUpperCase() === 'LEFT' && 
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" height={size} width={size}>
            <defs>
              <clipPath id="clip-arrow_left">
                <rect width="42" height="42"/>
              </clipPath>
            </defs>
            <g id="arrow_left" clipPath="url(#clip-arrow_left)">
              <path id="Path_69" data-name="Path 69" d="M1.55,0H1.512A1.548,1.548,0,0,0,0,1.444H0V1.46c0,.033,0,.066,0,.1V17.821a1.55,1.55,0,1,0,3.1,0V5.272l24.477,24.3a1.55,1.55,0,1,0,2.184-2.2L5.31,3.1H17.821a1.55,1.55,0,0,0,0-3.1Z" transform="translate(0 21.367) rotate(-45)" fill={fill}/>
            </g>
          </svg>
        }
        {props.children}
        {direction.toUpperCase() === 'RIGHT' &&
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" height={size} width={size}>
            <defs>
              <clipPath id="clip-arrow_right">
                <rect width="42" height="42"/>
              </clipPath>
            </defs>
            <g id="arrow_right" clipPath="url(#clip-arrow_right)">
              <path id="Path_69" data-name="Path 69" d="M90.55-63.975h-.038a1.544,1.544,0,0,1-.532-.108,1.545,1.545,0,0,1-.53-.35,1.545,1.545,0,0,1-.349-.541,1.543,1.543,0,0,1-.1-.445h0v-.016c0-.033,0-.066,0-.1V-81.8a1.55,1.55,0,0,1,1.55-1.55A1.55,1.55,0,0,1,92.1-81.8v12.549l24.477-24.3a1.55,1.55,0,0,1,2.192.008,1.549,1.549,0,0,1-.008,2.191L94.31-67.075h12.511a1.55,1.55,0,0,1,1.55,1.55,1.55,1.55,0,0,1-1.55,1.55Z" transform="translate(150.768 39.063) rotate(-135)" fill={fill}/>
            </g>
          </svg>
        }
        </Box>
    </React.Fragment>
  );
}

export default ArrowLabel;
