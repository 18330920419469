import React from 'react';
import BusinessLayout from '../../../content/business/layout';
import {Helmet} from "react-helmet";
import BusinessProductsManage from '../../../content/business/products';



const BusinessAccountServices = props => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Yupeto: Your Calendars</title>
      </Helmet>

      <BusinessLayout>

        <BusinessProductsManage />

      </BusinessLayout>
    </React.Fragment>
  );
}

export default BusinessAccountServices;
