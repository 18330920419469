  
const userReducer = (state = {
    profile: null,
    properties: null,
    status: 'loading',
    msg: ''
}, action) => {
    switch(action.type){
        case "USER_LOGIN":
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'error'?action.payload:'',
                profile: action.status === 'in'?action.payload:null
            }
            break;
        case "USER_LOGOUT":
            state = {
                ...state,
                status: 'out',
                msg: '',
                profile: null
            }
            break;
        case "USER_PROFILE_UPDATE":
            
            state = {
                ...state,
                profile: {
                    ...state.profile,
                    displayName: typeof action.payload.displayName !== 'undefined' ? action.payload.displayName : state.profile.displayName,
                    email: typeof action.payload.email !== 'undefined' ? action.payload.email : state.profile.email,
                    photoURL: typeof action.payload.photoURL !== 'undefined' ? action.payload.photoURL : state.profile.photoURL,
                }
            }
                
            break;
        case "USER_PROPERTIES_UPDATE":
            state = {
                ...state,
                properties: action.payload
            }
            break;
        default:
    }
    return state;
}

export default userReducer;