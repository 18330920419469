export function bookingsLoad (payload) {
    return dispatch => {

        dispatch({
            status: 'OK',
            payload: payload,
            type: 'BOOKINGS_LOAD'
        })

    }
}
