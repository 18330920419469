import { CircularProgress, Link, Alert } from "@mui/material";
import React, { useContext, useState } from "react";
import globalContext from "../../context/globalContext";
import firebase from 'firebase/app';
import 'firebase/auth';

const ProfileEmailVerified = props => {
    const context = useContext(globalContext);
    const [ sending, setSending ] = useState(false);
    const [ result, setResult ] = useState(null);

    const handleResend = () => {
        setSending(true);
        firebase.auth().currentUser.sendEmailVerification().then(
            () => {
                setSending(false);
                setResult('SUCCESS');
            }
        )
        .catch(err => {
            setResult('ERROR');
        })
    }

    return (
        <React.Fragment>
            {(!context.user.profile.emailVerified && result === null && !sending) && <Alert severity="warning">Your email address has not been verified. <Link href="#" onClick={handleResend}>Resend now.</Link></Alert>}
            {sending && <Alert severity="warning"><CircularProgress color="inherit" size={18}/></Alert>}
            {(!context.user.profile.emailVerified && result === 'SUCCESS') && <Alert severity="success">Email verification sent. Please check your email.</Alert>}
            {(!context.user.profile.emailVerified && result === 'ERROR') && <Alert severity="error">Something went wrong resending your verification email.</Alert>}
        </React.Fragment>
    )
}

export default ProfileEmailVerified