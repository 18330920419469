import React from 'react';
import {TextField, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  large: {
    padding: '31px 22px 14px'
  },
  largeLabel: {
    transform: 'translate(22px, 26px) scale(1)'
  }
}))

const InputField = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {validation, name, value, handleChange, variant, size} = props;
  const type = props.type || 'text';
  const multiline = validation.multiline || false;
  const rows = multiline ? validation.rows || 4 : null;
  const shrink = props.shrink;
  const autoFocus = typeof props.autoFocus !== 'undefined' ? props.autoFocus : false;

  
  const inputProps = () => {
    let props = {};
    if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
      props = {
        classes: {
          input: classes.large
        }
      }

    return props;
  }
  
  const inputLabelProps = () => {
    let props = {};
    if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
      props = {
        classes: {
          root: classes.largeLabel
        }
      }

    if(typeof shrink !== 'undefined')
      props.shrink = shrink;

    return props;
  }

  return (
        <div className="input-control">
            <TextField 
              type={type} 
              multiline={multiline}
              rows={rows}
              name={name} 
              label={validation.display_name} 
              variant={typeof variant !== 'undefined' ? variant : 'standard'} 
              fullWidth 
              value={value} 
              onChange={handleChange} 
              error={validation.dirty && validation.error} helperText={validation.dirty && validation.error ? validation.msg : ''}
              InputProps={inputProps()}
              InputLabelProps={inputLabelProps()}
              autoFocus={autoFocus}
            />
        </div>
  );
}

export default InputField;
