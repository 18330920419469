import React, {useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';

import { login } from '../../actions/userActions';
import { makeValidation, validateForm } from '../../global/formHelper';

import InputField from '../../components/inputField';
import globalContext from '../../context/globalContext';
import { Alert } from '@mui/material';
import MicroActionButtonGroup from '../../components/microActionButtonGroup';


const Reauthorise = props => {

  const context = useContext(globalContext);

  const [form, setForm] = useState({
    password: ''
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'password',
      display_name: 'Enter your current password',
      required: true
    }
  ]));

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    handleValidate();
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleValidate = () => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }

  const handleSubmit = () => {

    setWaiting(true);
    setError(null);
    const cred = firebase.auth.EmailAuthProvider.credential(context.user.profile.email, form.password);
    firebase.auth().currentUser.reauthenticateWithCredential(cred)
    .then((userCredential) => {

        setWaiting(false);

        // Signed in 
        props.login({
          status: 'in',
          payload: userCredential.user
        })

        if(typeof props.handleSuccess === 'function')
          props.handleSuccess();

    })
    .catch((error) => {
      setWaiting(false);
      if(typeof error.code !== 'undefined')
        switch(error.code){
          case 'auth/wrong-password':
            setError('Password incorrect');
            break;
          default:
            setError('An error occured');
            break;
        }
      else
        setError('An error occured');
    });

  }

  const handleCancel = () => {
    if(typeof props.onCancel === 'function')
      props.onCancel();
  }

  return (
    <React.Fragment>
      {error !== null && <Alert severity="error">{error}</Alert>}
      <InputField name='password' size='large' type='password' shrink={true} validation={validation.password} value={form.password} handleChange={handleInputChange} />
      <MicroActionButtonGroup onClose={handleCancel} onSubmit={handleSubmit} waiting={waiting} closeLabel="Cancel" submitLabel="Confirm"/>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (fields) => {
            dispatch(login(fields));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reauthorise);
