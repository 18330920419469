import { Alert, Box } from "@mui/material";
import React, {useEffect, useState} from "react";

const AlertStack = props => {

    const {severity} = props;
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {

        if(typeof props.alerts !== 'undefined')
            setAlerts(props.alerts);

    }, [props.alerts])

    return (
        <React.Fragment>
            {alerts.length > 0 &&
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    {alerts.map((a, idx) => (
                        <Alert key={idx} severity={severity}>{a}</Alert>
                    ))}
                </Box>
            }
        </React.Fragment>
    )

}

export default AlertStack;