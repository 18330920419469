import { Button, Box } from "@mui/material";
import React from "react"
import BusinessProductSlot from "./businessProductSlot";
import { generateShortId } from "../../../global/helpers";

const BusinessProductSlots = props => {

    const handleAddSlot = () => {
        let newSlots = JSON.parse(JSON.stringify(props.value));
        newSlots.push({
            id: generateShortId(),
            description: `Slot ${props.value.length+1}`,
            qty: 1,
            id_pricing: props.pricing.length > 0 ? props.pricing[0].id : null
        });
        if(typeof props.handleSelectionChange === 'function')
            props.handleSelectionChange(props.name, newSlots);
    }

    const handleUpdateSlot = (idx, val) => {
        let newSlots = JSON.parse(JSON.stringify(props.value));
        newSlots[idx] = val;
        if(typeof props.handleSelectionChange === 'function')
            props.handleSelectionChange(props.name, newSlots);
    }

    const handleDeleteSlot = (idx) => {
        let newSlots = JSON.parse(JSON.stringify(props.value));
        newSlots.splice(idx, 1);
        if(typeof props.handleSelectionChange === 'function')
            props.handleSelectionChange(props.name, newSlots);
    }

    return (
        <React.Fragment>

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, pb: 2}}>
                {props.value.map((slot, idx) => (
                    <BusinessProductSlot slot={slot} pricing={props.pricing} key={idx} idx={idx} handleUpdate={handleUpdateSlot} handleDelete={handleDeleteSlot} />
                ))}
            </Box>

            <Button variant="contained" size="small" color="secondary" onClick={handleAddSlot}>Create Slot</Button>

        </React.Fragment>
    )
}

export default BusinessProductSlots