import React, { useContext } from 'react';
import {useTheme} from '@mui/material';
import { makeStyles } from '@mui/styles'
import globalContext from '../../context/globalContext';
import images from '../../assets/imgs/index';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  egg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1
  },
  avatar: {
    display: 'flex',
    width: '76%',
    height: '76%',
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: `2px solid #fff`,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  image: {
    width: 'auto',
    height: '100%',
  }
}));


const ProfileAvatar = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const size  = parseInt(props.size) || 100;

  return (
    <React.Fragment>
      {context.user.status === 'in' && 
          <div className={classes.wrapper} style={{width: size, height: size}}>
            <img src={images.avatar_egg} alt="Your account" className={classes.egg} />
            <div className={classes.avatar}>
              {!props.children && <img src={context.user.profile.photoURL || images.avatar_default} className={classes.image}/>}
              {props.children && props.children}
            </div>
          </div>
      }
    </React.Fragment>
  );
}

export default ProfileAvatar;
