import React, {useLayoutEffect, lazy, Suspense} from 'react';
import {connect} from 'react-redux';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'

import {useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';

import Main from './context/main';
import YupetoFullLoader from './components/yupetoFullLoader';
import BusinessAccountServices from './routes/business/account/services';
import Home from './routes/home';

const Signin = lazy(() => import('./routes/signin/signin'));
const Register = lazy(() => import('./routes/signin/register'));
const Forgot = lazy(() => import('./routes/signin/forgot'));
const BusinessRegister = lazy(() => import('./routes/business/register'));
const NotFound = lazy(() => import('./routes/notFound'));

const BusinessAccount = lazy(() => import('./routes/business/account'));
const BusinessAccountProfile = lazy(() => import('./routes/business/account/profile'));
const BusinessAccountManage = lazy(() => import('./routes/business/account/manage'));
const BusinessAccountWallchart = lazy(() => import('./routes/business/account/wallchart'));
const BusinessAccountBookings = lazy(() => import('./routes/business/account/bookings'));


const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  }
}))

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const App = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const isAuthorised = (component) => {
    if(props.user.status !== 'in')
      return Signin;

    return component;
  }

  const isSuper = (component) => {
    if(props.user.status !== 'in')
      return Signin;

    if(props.user.status === 'in' && props.user.properties !== null && props.user.properties.super === true)
      return component;

    return NotFound;
  }

  return (

    <Router>
      <Wrapper>
        <div className={classes.root}>
          <Main>
            <Suspense fallback={<YupetoFullLoader />}>
              <Switch>
                    <Route path={"/"} exact component={Home}></Route>
                    <Route path={"/business/register"} exact component={BusinessRegister}></Route>
                    <Route path={"/business/account"} exact component={isAuthorised(BusinessAccount)}></Route>
                    <Route path={"/business/account/:id"} exact component={isAuthorised(BusinessAccount)}></Route>
                    <Route path={"/business/account/profile/:id"} exact component={isAuthorised(BusinessAccountProfile)}></Route>
                    <Route path={"/business/account/manage/:id"} exact component={isAuthorised(BusinessAccountManage)}></Route>
                    <Route path={"/business/account/services/:id"} exact component={isAuthorised(BusinessAccountServices)}></Route>
                    <Route path={"/business/account/wallchart/:id"} exact component={isAuthorised(BusinessAccountWallchart)}></Route>
                    <Route path={"/business/account/bookings/:id"} exact component={isAuthorised(BusinessAccountBookings)}></Route>
                    <Route path={"/signin"} exact component={Signin}></Route>
                    <Route path={"/register"} exact component={Register}></Route>
                    <Route path={"/forgot_password"} exact component={Forgot}></Route>
                    {/* <Route path={"/find"} exact component={Find}></Route>
                    <Route path={"/find/:slug"} exact component={Find}></Route>
                    <Route path={"/page/:id"} exact component={ProfilePage}></Route> */}                   
                    <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Main>
        </div>
      </Wrapper>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {})(App);
