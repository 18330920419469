import { Box } from "@mui/material"
import firebase from 'firebase/app';
import 'firebase/auth';
import React, {useState, useContext, useEffect} from "react";
import globalContext from "../../context/globalContext";
import InputField from '../../components/inputField';
import { makeValidation, validateForm } from '../../global/formHelper';
import MicroActionButtonGroup from "../../components/microActionButtonGroup";

const ProfileEditName = props => {
    const context = useContext(globalContext);

    const sx = {
      container: {
        display: 'flex',
        flexDirection: 'column'
      },
      buttonGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'flex-end'
      }
    }

    const [waiting, setWaiting] = useState(false);

    const [form, setForm] = useState({
      display_name: context.user.profile.displayName || ''
    })
  
    const [validation, setValidation] = useState(makeValidation([
      {
        name: 'display_name',
        display_name: 'Your Name',
        required: true
      }
    ]));
    
    useEffect(() => {
        handleValidate();
    }, [form])

    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleValidate = () => {
      const newValidation = validateForm(form, validation);
      setValidation(newValidation);
    }
  
    const handleSubmit = () => {
      
      if(form.display_name !== context.user.profile.displayName){
        setWaiting(true);
        firebase.auth().currentUser.updateProfile({displayName: form.display_name})
        .then(() => {
          setWaiting(false);
          context.updateProfile({displayName: form.display_name})

          if(typeof props.onSuccess === 'function')
            props.onSuccess('Your name has been updated');
        })
        .catch((err) => {
          setWaiting(false);
          //... throw an error
        }); 

      } else
        handleClose();

    }

    const handleClose = () => {
      if(typeof props.onClose === 'function')
        props.onClose();
    }

    return (
      <React.Fragment>
        <Box sx={sx.container}>
          <InputField name='display_name' validation={validation.display_name} value={form.display_name} handleChange={handleInputChange} autoFocus={true} />
          <MicroActionButtonGroup onSubmit={handleSubmit} onClose={handleClose} waiting={waiting} />
        </Box>
      </React.Fragment>
    )
}

export default ProfileEditName;