import firebase from 'firebase/app';
import 'firebase/firestore';

export function metaLoadAnimalTypes () {

    return async dispatch => {

        const result = await firebase.firestore().collection("animal_types").get();
        let data = [];
        result.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data()
            }); 
        });
        dispatch({
            type: 'META_ANIMAL_TYPES',
            payload: data
        })

    }

}

export function metaLoadServiceTypes () {

    return async dispatch => {

        const result = await firebase.firestore().collection("service_types").get();
        let data = [];
        result.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data()
            }); 
        });
        dispatch({
            type: 'META_SERVICE_TYPES',
            payload: data
        })

    }

}