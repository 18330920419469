import React, { useState, useEffect, useContext, useRef } from 'react';
import {useTheme, Typography, CircularProgress, Button, Box, Dialog, DialogContent, Alert} from '@mui/material';
import {makeStyles} from '@mui/styles';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import globalContext from '../context/globalContext';
import firebase from 'firebase/app';
import 'firebase/storage';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    uploadWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
        maxWidth: 700,
        overflow: 'hidden'
    },
    uploadImage: {
        width: '100%',
        height: '100%',
        position: 'relative',
        border: `2px dashed ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center'
    },
    avatar: {
        borderRadius: '50%'
    },
    uploadHover: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: (0,0,0,0),
        top: 0,
        left: 0,
        cursor: 'pointer',
        zIndex: 120
    },
    dropHighlight: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        border: `2px dashed ${theme.palette.secondary.main}`,
        backgroundColor: 'rgba(255,255,255,.9)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey[700]
    },
    loadingHighlight: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        border: `2px dashed ${theme.palette.secondary.main}`,
        backgroundColor: 'rgba(255,255,255,.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey[700]
    },
    remove: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#fff',
        zIndex: 121
    },
}));

const UploadImage = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {childRef, docRef } = props;
    const variant = typeof props.variant !== 'undefined' ? props.variant.toUpperCase() : 'SQUARE';
    const maxUploadSize = typeof props.maxUploadSize !== 'undefined' ? props.maxUploadSize : 5000;

    const [imageUrl, setImageUrl] = useState(null);

    const [ dropHighlight, setDropHighlight ] = useState(false)
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ progress, setProgress ] = useState(0);

    const dropArea = useRef(null);
    const fileInput = useRef(null);

    useEffect(() => {
        if(typeof props.imageUrl !== 'undefined'){
            setImageUrl(props.imageUrl);
        }else
            setImageUrl(null);
    }, [props.imageUrl])



    useEffect(() => {

        const preventDefaults = (e) => {
            e.preventDefault()
            e.stopPropagation()
        }
        
        const dropHighlight = e => {
            preventDefaults(e);
            setDropHighlight(true);
        }
        
        const dropLeave = e => {
            preventDefaults(e);
            setDropHighlight(false);
        }

        dropArea.current.addEventListener('dragenter', dropHighlight, false)
        dropArea.current.addEventListener('dragover', dropHighlight, false)
        dropArea.current.addEventListener('dragleave', dropLeave, false)
        dropArea.current.addEventListener('drop', e => {
            dropLeave(e);
            handleUpload(e.dataTransfer.files[0]);
        }, false)

    }, [])

    const handleSelectFile = e => {
        if(typeof fileInput.current !== 'undefined')
            fileInput.current.click();
    }

    const handleFileChange = e => {
        if(typeof e.target.files[0] !== 'undefined'){
            if(e.target.files[0].size < maxUploadSize*1000)
                handleUpload(e.target.files[0]);
            else
                setError(`The maximum file size you may upload is ${Math.floor(maxUploadSize/1000)}mb`);
        }
    }

    const handleDelete = e => {
        e.preventDefault()
        e.stopPropagation()
        
        if(typeof props.handleDelete === 'function')
            props.handleDelete(imageUrl);
    }

    const handleUpload = (file) => {

        if(typeof file !== 'undefined') {
            setLoading(true);
            setProgress(0);

            // Create a root reference
            const storageRef = firebase.storage().ref();

            // Create the file metadata
            const metadata = {
                contentType: 'image/jpeg'
            };
            
            // Upload file and metadata to the object 'images/mountains.jpg'
            const uploadTask = storageRef.child(`${childRef}/${docRef}-${new Date().getTime()}.jpg`).put(file, metadata);
            
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => {
                
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(p);

                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                        break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                        break;
                    }

                }, 
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        setError(`Sorry you don't have permission to uplaod this file`);
                        break;
                        case 'storage/canceled':
                        // User canceled the upload
                        setError(`Upload cancelled`);
                        break;
                        case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        setError(`Sorry an unknown error occured`);
                        break;
                    }

                    setLoading(false);
                    setProgress(0);
                }, 
                () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setLoading(false);
                    setProgress(0);

                    if(typeof props.handleSuccess === 'function')
                        props.handleSuccess(downloadURL);
                });
                }
            );
        }
    }

  

  return (
      <React.Fragment>

        {(imageUrl !== null && typeof props.handleDelete === 'function') && <Box onClick={handleDelete} sx={{position: 'absolute', top: 16, right: 16, color: '#fff', zIndex: 121, backgroundColor: 'secondary.main', borderRadius: 16, height: 24, width: 24, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}} size="small"><CloseIcon sx={{fontSize: 18}} /></Box>}
      
        <div className={clsx([classes.uploadWrapper, variant === 'AVATAR' && classes.avatar])} onClick={handleSelectFile}>

            {imageUrl !== null && 
                <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                    <React.Fragment>
                        <AddCircleIcon sx={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', opacity: .7, color: '#fff'}}/>
                        <img src={imageUrl} style={{width: 'auto', height: '100%',}} />
                    </React.Fragment>
                </Box>
            }

            {!imageUrl && 
                <div className={clsx([classes.uploadImage, variant === 'AVATAR' && classes.avatar])}>
                    <React.Fragment>
                        <AddCircleIcon color="secondary" />
                        <Typography component="div" color="secondary" variant="caption">Drag or browse</Typography>
                    </React.Fragment>
                </div>
            }


            {dropHighlight && 
                <div className={clsx([classes.dropHighlight, variant === 'AVATAR' && classes.avatar])}>
                    <VerticalAlignBottomIcon size="small" /> 
                    <Typography>Drop</Typography>
                </div>
            }



            {loading && 
                <div className={clsx([classes.dropHighlight, variant === 'AVATAR' && classes.avatar])}>
                    <CircularProgress size={24} variant="determinate" value={progress} />
                </div>
            }

            <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInput}
            />

            <div className={clsx([classes.uploadHover, variant === 'AVATAR' && classes.avatar])} ref={dropArea}></div>

        </div>

        <Dialog open={error !== null} onClose={() => setError(null)}><DialogContent><Alert severity='error'>{error}</Alert><Button onClick={() => setError(null)} sx={{mt: 1}} variant="contained"  color="secondary">OK</Button></DialogContent></Dialog>
    
    </React.Fragment>

  );
}

export default UploadImage;
