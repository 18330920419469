import { Collapse, Alert } from "@mui/material"
import React, {useState, useContext, useEffect} from "react";
import globalContext from "../../context/globalContext";
import InputField from '../../components/inputField';
import { makeValidation, validateForm } from '../../global/formHelper';
import Reauthorise from "../signin/reauthorise";
import MicroActionButtonGroup from "../../components/microActionButtonGroup";
import firebase from 'firebase/app';
import 'firebase/auth';

const ProfileChangePassword = props => {

    const context = useContext(globalContext);

    const [ reauthenticate, setReauthenticate ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(false);
    const [ waiting, setWaiting ] = useState(false);

    const [form, setForm] = useState({
      new_password: '',
      confirm_password: '',
    })
  
    const [validation, setValidation] = useState(makeValidation([
      {
        name: 'new_password',
        display_name: 'Change Password',
        required: true
      },
      {
        name: 'confirm_password',
        display_name: 'Confirm Password',
        required: true,
        additional: [
          {
            type: 'MATCH',
            field: 'new_password',
            msg: 'Your passwords do not match'
          }
        ]
      }
    ]));
    
    useEffect(() => {
        handleValidate();
    }, [form])

    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleValidate = () => {
      const newValidation = validateForm(form, validation);
      setValidation(newValidation);
    }
  
    const handleSubmit = () => {

      setSuccess(false);
      setError(null);
      setWaiting(true);
      
      firebase.auth().currentUser.updatePassword(form.new_password)
      .then(() => {
        setWaiting(false);

        if(typeof props.onSuccess === 'function')
          props.onSuccess('Your password has been changed');
        else
          setSuccess('Your password has been changed');
      })
      .catch((err) => {
        setWaiting(false);
        //... throw an error
        if(typeof err.code !== 'undefined'){
          switch(err.code){
              case 'auth/requires-recent-login':
                setReauthenticate(true);
                break;
              case 'auth/weak-password':
                setError('The password you entered is too weak, try entering a stronger password.')
                break;
              default:
                setError('Something went wrong');
          }
        }
        else
          setError('Something went wrong');
      }); 

    }

    const handleAuthorised = () => {
      setReauthenticate(false);
      handleSubmit();
    }

    const handleClose = () => {
      if(typeof props.onClose === 'function')
        props.onClose();
    }

    return (
      <React.Fragment>
        <div className="flex-col flex pb">
            {error !== null && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <InputField name='new_password' type="password" shrink={true} validation={validation.new_password} value={form.new_password} handleChange={handleInputChange} autoFocus={true} />
            <InputField name='confirm_password' type="password" shrink={true} validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} />
            <Collapse in={reauthenticate}><Reauthorise handleSuccess={handleAuthorised} onCancel={handleClose} /></Collapse>
            {!reauthenticate && <MicroActionButtonGroup onClose={handleClose} onSubmit={handleSubmit} waiting={waiting} />}
        </div>
      </React.Fragment>
    )
}

export default ProfileChangePassword;