import { Typography, useTheme, Box, IconButton } from "@mui/material"
import {makeStyles} from '@mui/styles';
import React, { useContext, useState } from "react";
import { withRouter } from 'react-router-dom';
import globalContext from "../context/globalContext";
import ProfileAvatar from "./profile/profileAvatar";
import ProfileEmailVerified from "../content/profile/profileEmailVerified";
import StackSlidePanel from "../components/stackSlidePanel";
import ProfileEdit from './profile/profileEdit';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme) => ({
    avatarWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30
    },
    avatarInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        marginTop: 30,
        marginBottom: 10
    }
}))

const Profile = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [showEdit, setShowEdit] = useState(false);

    return (
        <React.Fragment>

            <div className={classes.avatarWrapper}>
                <ProfileAvatar size="140" />
                <div className={classes.avatarInfo}>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '6px', justifyContent: 'center'}}>
                        <Typography variant="subtitle1" align="center">{context.user.profile.displayName}</Typography>
                        <IconButton onClick={() => setShowEdit(true)} color="secondary" size="small"><EditIcon sx={{fontSize: 18, marginRight: '4px'}} /></IconButton>
                    </Box>
                    <ProfileEmailVerified />
                </div>
            </div>

            <StackSlidePanel show={showEdit} handleClose={() => setShowEdit(false)} title="Edit Profile">
                <ProfileEdit />
            </StackSlidePanel>

        </React.Fragment>
    )
}

export default withRouter(Profile)