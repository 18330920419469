import React, {useEffect, useReducer, useRef, useState} from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import {connect} from 'react-redux';
import { logout, login, updateProfile, updateProperties } from '../actions/userActions';
import { metaLoadAnimalTypes, metaLoadServiceTypes } from '../actions/metaActions';
import { organisationsLoad, organisationActive, organisationSubscription, organisationPending } from '../actions/organisationActions';
import { productsLoad } from '../actions/productActions';
import { bookingsLoad } from '../actions/bookingActions';

import {useTheme, Drawer} from '@mui/material';
import {makeStyles} from '@mui/styles';

import GlobalContext from './globalContext';
import MainMenu from '../content/mainMenu';
import { useQuery } from '../global/hooks';
import CookieBanner from '../components/cookieBanner';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  inner: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
}))


const Main = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const query = useQuery();

  const db = firebase.firestore();
  const functions = firebase.functions();

  const [menuOpen, setMenuOpen] = useState(false);
  let organisationsUnsubscribe;
  let subscriptionUnsubscribe;
  let productsUnsubscribe;
  let bookingsUnsubscribe;


  const handleLoadProperties = async () => {

    try {

      const currentUser = firebase.auth().currentUser;
      if(currentUser === null)
        throw 'ERR_NOT_SIGNEDIN';

      const result = await db.collection("users").doc(currentUser.uid).get();
      let properties = result.data();
      if(typeof properties === 'undefined'){
        properties = {
          favourites: []
        }
        await db.collection("users").doc(props.user.profile.uid).set(properties)
      }

      props.updateProperties(properties);
      return properties
      
    } catch (error) {

      return false;

    }

  }

  const handleLoadOrganisations = async () => {

    if(props.user.status === 'in'){

      try {

        if(typeof organisationsUnsubscribe !== 'undefined' && organisationsUnsubscribe !== null)
          organisationsUnsubscribe();

        
        organisationsUnsubscribe = db.collection("organisations")
          .where("quick_members", "array-contains", props.user.profile.uid)
          .onSnapshot(
            organisationsSnapShot => {

              let data = [];
              organisationsSnapShot.forEach((doc) => {
                data.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              props.organisationsLoad(data);

              if(props.organisations.activeId === null && data.length > 0)
                props.organisationActive(data[0].id)

            }
          );
        
        
      } catch (error) {
        console.log(typeof error === 'string' ? error : 'There was a problem loading your services');
      }

    }

    if(props.user.status !== 'in' && props.organisations.data.length > 0)
      props.organisationsLoad([]);

  }

  const subscriptionRef = useRef(props.organisations.activeSubscription);
  subscriptionRef.current = props.organisations.activeSubscription;

  const handleLoadSubscription = async () => {

    if(props.organisations.activeId !== null){
      if(typeof subscriptionUnsubscribe !== 'undefined' && subscriptionUnsubscribe !== null)
        subscriptionUnsubscribe();

      subscriptionUnsubscribe = db.collection('subscriptions').doc(props.organisations.activeId)
      .onSnapshot((docRef) => {
        const data = docRef.data();
        if((data?.stripe_subscription_status !== "pending_active" && data?.stripe_subscription_status !== "incomplete") || subscriptionRef.current === null)
          props.organisationSubscription(data);
      })
    }

  }


  const handleLoadProducts = async () => {

    if(props.organisations.activeId !== null){
      if(typeof productsUnsubscribe !== 'undefined' && productsUnsubscribe !== null)
      productsUnsubscribe();

      productsUnsubscribe = db.collection('organisations').doc(props.organisations.activeId).collection('products')
      .onSnapshot((docRef) => {
        let data = [];
        docRef.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data()
          });
        });
        props.productsLoad(data);
      })
    }

  }


  const handleLoadBookings = async () => {

    if(props.organisations.activeId !== null){
      if(typeof bookingsUnsubscribe !== 'undefined' && bookingsUnsubscribe !== null)
      bookingsUnsubscribe();

      bookingsUnsubscribe = db.collection('organisations').doc(props.organisations.activeId).collection('bookings').where('cancelled_on', '==', null)
      .onSnapshot((docRef) => {
        let data = [];
        docRef.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data()
          });
        });
        props.bookingsLoad(data);
      })
    }

  }

  useEffect(() => {

    handleLoadSubscription();
    handleLoadProducts();
    handleLoadBookings();

  }, [props.organisations.activeId])

  useEffect(() => {
    handleLoadProperties();
    handleLoadOrganisations();
  }, [props.user.profile])

  
  useEffect(() => {

    fetchUserProfile();
    props.metaLoadAnimalTypes();
    props.metaLoadServiceTypes();

    // Check for coupon codes
    const code = query.get('vc');
    if(typeof code !== 'undefined' && code !== null)
      localStorage.setItem('vc', code);

  }, [])

  const fetchUserProfile = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user)
        props.login({
          status: 'in',
          payload: user
        })
    });

    console.log(firebase.auth().currentUser);
    if(firebase.auth().currentUser === null)
      props.login({
        status: 'out',
        payload: null
      })
  }


  return (
    <GlobalContext.Provider value={{
      ...props,
      db: db,
      functions: functions,
      toggleMenuOpen: setMenuOpen,
      fetchUserProfile,
      loadOrganisations: handleLoadOrganisations,
      reloadSubscription: handleLoadSubscription,
    }}>
      <MainMenu open={menuOpen} />

      <div className={classes.container}>
        <div className={classes.inner}>
          {props.children}
        </div>
      </div>

      <CookieBanner />
    </GlobalContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    meta: state.meta,
    organisations: state.organisations,
    products: state.products,
    bookings: state.bookings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
        dispatch(login(payload));
    },
    logout: () => {
        dispatch(logout());
    },
    updateProfile: (payload) => {
        dispatch(updateProfile(payload));
    },
    updateProperties: (payload) => {
        dispatch(updateProperties(payload));
    },
    metaLoadServiceTypes: () => {
      dispatch(metaLoadServiceTypes());
    },
    metaLoadAnimalTypes: () => {
      dispatch(metaLoadAnimalTypes());
    },
    organisationsLoad: (payload) => {
        dispatch(organisationsLoad(payload));
    },
    organisationActive: (payload) => {
        dispatch(organisationActive(payload));
    },
    organisationSubscription: (payload) => {
        dispatch(organisationSubscription(payload));
    },
    organisationPending: (payload) => {
        dispatch(organisationPending(payload));
    },
    productsLoad: (payload) => {
        dispatch(productsLoad(payload));
    },
    bookingsLoad: (payload) => {
        dispatch(bookingsLoad(payload));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
