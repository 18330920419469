import React, { useContext, useEffect, useState} from 'react';
import { Typography, useTheme, Alert, Box, Button, List, ListItemButton, ListItemText, ListItemIcon} from '@mui/material';
import globalContext from '../../../context/globalContext';
import { useParams, useHistory } from 'react-router';
import YupetoLoader from '../../../components/yupetoLoader';
import YupetoFullLoader from '../../../components/yupetoFullLoader';
import BusinessProductCard from './businessProductCard';
import BusinessProductModify from './businessProductModify';
import Edit from '@mui/icons-material/Edit';
import { types} from './models';



const BusinessProductsManage = props => {

  const theme = useTheme();
  const context = useContext(globalContext);
  const history = useHistory();
  const {id} = useParams();
  
  const [organisation, setOrganisation] = useState(null);
  const [showModify, setShowModify] = useState(false);
  const [modifyProduct, setModifyProduct] = useState(null);

  useEffect(() => {
    // When the organisation data changes and providing there are some organisations and the status is OK
    if(context.organisations.data.length > 0 && context.organisations.status === 'OK'){

      // Grab the organisation record with the id of the param
      const getOrganisation = context.organisations.data.find(o => o.id === id);

      // If the organisation has changed update the record
      if(typeof getOrganisation !== 'undefined' && JSON.stringify(organisation) !== JSON.stringify(getOrganisation))
        setOrganisation(getOrganisation);

    }

  }, [context.organisations.data])

  const handleAddNew = () => {
    setShowModify(true);
    setModifyProduct(null);
  }

  const handleModify = p => {
    console.log('modifying');
    setModifyProduct(p);
  }

  const handleCloseModify = () => {
    setShowModify(false);
    setModifyProduct(null);
  }

  useEffect(() => {

    if(modifyProduct !== null && showModify === false)
      setShowModify(true);

  }, [modifyProduct])

  return (
    <React.Fragment>

        {context.organisations.status === 'LOADING' && <YupetoFullLoader />}
        {context.organisations.status === 'ERROR' && <Alert severity="error">{context.organisations.msg}</Alert>}
        {organisation !== null && 
          <React.Fragment>

            {!showModify &&
                <React.Fragment>
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Typography variant="h6">Services</Typography>
                        <Typography variant="caption">Update and manage your services.</Typography>
                    </Box>
                    <Box sx={{minWidth: 140, display: 'flex', justifyContent: 'flex-end'}}>
                      <Box>
                        <Button variant="contained" onClick={handleAddNew} color="secondary">New</Button>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{pt: 3}}>
                    {context.products.status === 'LOADING' && <YupetoLoader />}
                    {context.products.status !== 'LOADING' && 
                      <React.Fragment>
                        {context.products.data.length === 0 && <Typography variant="body1" color="textSecondary">No services</Typography>}
                        {context.products.data.length > 0 && 
                          <>
                          <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap'}}>
                            {context.products.data.filter(p => p.live === true).map((p, idx) => (
                              <BusinessProductCard key={idx} product={p} handleOpenModify={handleModify} />
                            ))}
                          </Box>  

                          {context.products.data.filter(p => p.live === false).length > 0 &&
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, flexWrap: 'wrap', mt: 4}}>
                              <Typography variant="h6">Disabled services</Typography>
                              <List>
                                {context.products.data.filter(p => p.live === false).map((p, idx) => (
                                  <ListItemButton key={idx} onClick={() => handleModify(p)}>
                                    <ListItemText primary={p.name} secondary={types.find(t => t.key === p.type).val} />
                                    <ListItemIcon><Edit color="secondary" sx={{fontSize: '24px'}} /></ListItemIcon>
                                  </ListItemButton>
                                ))}
                              </List>
                            </Box>  
                          }
                          </>
                        }
                      </React.Fragment>
                    }
                  </Box>
                </React.Fragment>
            }

            {showModify && <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2}}><BusinessProductModify organisation={organisation} product={modifyProduct} onClose={handleCloseModify} onSuccess={handleCloseModify} /></Box>}


          </React.Fragment>
        }

    </React.Fragment>
  );
}

export default BusinessProductsManage;