import { Button, Card, Typography, Box, Chip } from "@mui/material";
import ArrowLabel from "../../../components/arrowLabel";
import EditIcon from '@mui/icons-material/Settings';
import { types, availability} from './models';
import images from "../../../assets/imgs";


const BusinessProductCard = props => {

    const {product} = props;

    const sx = {
        card: {
            width: '100%',
            maxWidth: '400px',
            p: 4
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: 3
        },
        box: {
            width: '100%',
            height: '160px',
            display: 'flex',
            justifyContent:'center',
            pt: 2,
            pb: 2,
            mt: 2,
            mb: 2,
            borderRadius: 3
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            gap: 0
        },
        availability: {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
            pt: 1,
            pb: 1
        }
    }

    const type = types.find(t => t.key === product.type);
    
    const getAvailability = () => {
        
        return (
            <Box sx={sx.availability}>
                {product.availability.map((av, idx) => (<Chip key={idx} size="small" color="primary" label={availability.find(a => a.key === av).val.charAt(0)} />))}
            </Box>
        )

    }

    const handleModify = () => {
        if(typeof props.handleOpenModify === 'function')
            props.handleOpenModify(product)
    }

    return (
        <Card sx={sx.card}>

            <Box sx={sx.header}>
                <Typography variant="h6" align="center">{product.name}</Typography>
                <Typography variant="caption" align="center">{type?.val}</Typography>
            </Box>

            <Box sx={sx.box}>
                <img src={images[`calendar_${product.type}`]} alt="calendar" style={{height: '100%', width: 'auto'}} />
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Typography variant="body2">{product.live ? 'Live' : 'Inactive'}</Typography>
                </Box>
                <Box>
                    <Typography variant="body2">{product.public ? 'Public' : 'Private'}</Typography>
                </Box>
            </Box>
            {getAvailability()}

            <Box sx={sx.footer}>
                <Button color="secondary" variant="outlined" onClick={handleModify}><EditIcon sx={{fontSize: 18, mr: 1}}/>Settings</Button>
                <Button variant="contained" color="secondary"><ArrowLabel>Bookings</ArrowLabel></Button>
            </Box>
        </Card>
    )
}

export default BusinessProductCard;