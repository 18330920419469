  
const metaReducer = (state = {
    animal_types: [],
    service_types: [],
}, action) => {
    switch(action.type){
        case "META_ANIMAL_TYPES":
            state = {
                ...state,
                animal_types: action.payload,
            }
            break;
        case "META_SERVICE_TYPES":
            state = {
                ...state,
                service_types: action.payload,
            }
            break;
        default:
    }
    return state;
}

export default metaReducer;