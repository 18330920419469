export function organisationsLoad (payload) {
    return dispatch => {

        dispatch({
            status: 'OK',
            payload: payload,
            type: 'ORGANISATIONS_LOAD'
        })

    }
}

export function organisationActive (payload) {
    return dispatch => {

        dispatch({
            payload: payload,
            type: 'ORGANISATION_ACTIVE'
        })

    }
}

export function organisationSubscription (payload) {
    return dispatch => {

        dispatch({
            payload: payload,
            type: 'ORGANISATION_SUBSCRIPTION'
        })

    }
}

export function organisationPending (payload) {
    return dispatch => {

        dispatch({
            payload: payload,
            type: 'ORGANISATION_PENDING'
        })

    }
}