import React, {useEffect, useContext, useState} from 'react';
import { Typography, Box, Alert, TextField, FormControlLabel, Switch, Card, Button} from '@mui/material';
import { periodic, multiple, single, types, availability} from './models';
import globalContext from '../../../context/globalContext';
import { makeValidation, validateForm, isValid } from '../../../global/formHelper';
import ProgressButton from '../../../components/progressButton';
import ArrowLabel from '../../../components/arrowLabel';
import ChipCloudSelection from '../../../components/chipCloudSelection';
import BusinessProductSlots from './businessProductSlots';
import BusinessProductPricings from './businessProductPricings';



const BusinessProductModify = props => {
  
  const {organisation, product} = props;
  const context = useContext(globalContext);

  const getForm = () => {
    if(product !== null){

      switch(product.type){
        case 'period':
          return {
            ...periodic,
            ...product
          }
        case 'multiple':
          return {
            ...multiple,
            ...product
          }
        default:
          return {
            ...single,
            ...product
          }
      }

    } else {

      switch(organisation?.service_types[0]){
        case 'BOARDING':
          return periodic;
        case 'DAYCARE':
        case 'SITTING':
        case 'WALKING':
          return multiple;
        default:
          return single;
      }

    }

  }

  const [form, setForm] = useState(getForm());

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'name',
      display_name: 'Name your service',
      required: true
    },
    {
      name: 'type',
      display_name: 'Booking type',
      required: true
    },
    {
      name: 'availability',
      display_name: 'Availability',
      required: true,
      additional: [
        {
          type: 'MIN',
          value: 1,
          msg: 'You must select at least one day you are available.'
        },
      ]
    },
    {
      name: 'slots',
      display_name: 'Slots',
      required: true,
      additional: [
        {
          type: 'MIN',
          value: 1,
          msg: 'You must create at least one bookable slots.'
        },
      ]
    },
    {
      name: 'pricing',
      display_name: 'Pricing',
      required: true,
      additional: [
        {
          type: 'MIN',
          value: 1,
          msg: 'You must create at least one pricing record.'
        },
      ]
    },
    {
      name: 'min',
      display_name: 'Min booking (days)',
      required: true,
      additional: [
        {
          type: 'MIN',
          value: 1,
          msg: 'Minimum booking must be at least 1'
        },
        {
          type: 'MAX',
          value: 90,
          msg: 'Minimum booking is 90 days'
        }
      ]
    },
    {
      name: 'max',
      display_name: 'Max booking (days)',
      required: true,
      additional: [
        {
          type: 'MIN',
          value: 1,
          msg: 'Maximum booking must be at least 1'
        },
        {
          type: 'MAX',
          value: 90,
          msg: 'Maximum booking is 90 days'
        }
      ]
    },
    {
      name: 'uptoDays',
      display_name: 'Book in advance (days)',
      required: true,
      additional: [
        {
          type: 'MIN',
          value: 7,
          msg: 'Minimum booking ahead must be at least 7'
        },
        {
          type: 'MAX',
          value: 365,
          msg: 'Maximum booking ahead is 365 days'
        }
      ]
    },
    {
      name: 'live',
      display_name: 'Live',
      required: true
    },
    {
      name: 'public',
      display_name: 'Public',
      required: true
    }
  ]));

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    handleValidate();
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSelectionChange = (key, val) => {
    let newForm = {...form};
    newForm[key] = val;
    setForm(newForm);
  }

  const handleAvailabilitySelectionChange = (key, val) => {
    val = val.sort((a, b) => a-b);
    if(val.indexOf(0) > -1){
      val.splice(0, 1);
      val.push(0);
    }
    let newForm = {...form};
    newForm.availability = val;
    setForm(newForm);
  }

  const handleValidate = () => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }

  const handleSubmit = () => {

    const newValidation = validateForm(form, validation, true);
    setValidation(newValidation);

    if(isValid(newValidation)) {

      if(product !== null && typeof product.id !== 'undefined')
        handleUpdate();
      else
        handleAdd();

    } else {

        setError('Please fill in all required fields');

    }
    

  }

  const handleAdd = () => {

    setError(null);
    setWaiting(true);

    const params = {
      ...form, 
      created_by: context.user.profile.uid
    };

    context.db.collection('organisations').doc(organisation.id).collection('products').add(params)
    .then((docRef) => {
        setWaiting(false);
        if(typeof props.onSuccess === 'function')
          props.onSuccess();
    })
    .catch((err) => {
        setWaiting(false);
        setError('Something went wrong adding your calendar. Please try again.');
        console.log(err);
    }); 

  }

  const handleUpdate = () => {

    setError(null);
    setWaiting(true);

    let params = {...form};
    delete params.id;

    context.db.collection('organisations').doc(organisation.id).collection('products').doc(product.id).update(params)
    .then((docRef) => {
        setWaiting(false);
        if(typeof props.onSuccess === 'function')
          props.onSuccess();
    })
    .catch((err) => {
        setWaiting(false);
        setError('Something went wrong adding your calendar. Please try again.');
        console.log(err);
    }); 

  }


  const handleClose = () => {
    if(typeof props.onClose === 'function')
      props.onClose();
  }

  return (
    <React.Fragment>

      <Box sx={{maxWidth: 600}}>

        <Box>
          <Button onClick={handleClose} color="secondary" sx={{pl: 0}}><ArrowLabel direction="LEFT" color="secondary" size={18}>Cancel</ArrowLabel></Button>
          {product === null && <Typography variant='h2'>Let's create a <Typography color="primary" variant="span">new service</Typography></Typography>}
          {product !== null && <Typography variant='h2'>Adjust <Typography color="primary" variant="span">a service</Typography></Typography>}
          {error && <Alert variant="filled" severity="error" sx={{marginBottom: 1}}>{error}</Alert>}
        </Box>

        <Card sx={{mt: 2}}>

          <Box sx={{pt:2, pb:2, pr:3, pl:3}}>

            <Box sx={{paddingTop: 2, paddingBottom: 2}}>

                <Typography variant='subtitle2'>{validation.name.display_name}</Typography>
                <Typography variant='caption' sx={{mb: 1}}>e.g. Rabbit boarding, Cat grooming, Dog Walking, Grooming with Hannah&hellip;</Typography>
                <TextField 
                  type="text" 
                  name="name"
                  label={null}
                  fullWidth 
                  value={form.name} 
                  onChange={handleInputChange} 
                  error={validation.name.dirty && validation.name.error} helperText={validation.name.dirty && validation.name.error ? validation.name.msg : ''}
                  autoFocus={true}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  placeholder="e.g. Small Animal Boarding"
                />

            </Box>


            <Box sx={{paddingTop: 2, paddingBottom: 2}}>
              <Typography variant='subtitle2' sx={{mb: 1}}>{validation.type.display_name}</Typography>
              <Typography variant='caption' sx={{mb: 1}}>Is this service taking bookings for a duration or 'period' of days, 'multiple' individual days or a 'single' slot?</Typography>
              {(validation.type.dirty && validation.type.error)  && <Typography variant='caption' color="error" sx={{mb: 1}} component="div">{validation.type.msg}</Typography>}
              <ChipCloudSelection 
                name='type' 
                label='Type'
                options={types} 
                selected={form.type} 
                handleSelectionChange={handleSelectionChange}
                key_name="key"
                value_name="val"
                mode="single"
              />
            </Box>
            
          </Box>

        </Card>


        <Card sx={{mt: 2}}>

          <Box sx={{pt:2, pb:2, pr:3, pl:3}}>
            <Box sx={{paddingTop: 2, paddingBottom: 2}}>
              <Typography variant='subtitle2'>Set when this service is available</Typography>
              <Typography variant='caption' sx={{mb: 1}}>What days are you available for bookings?</Typography>
              {(validation.availability.dirty && validation.availability.error)  && <Typography variant='caption' color="error" sx={{mb: 1}} component="div">{validation.availability.msg}</Typography>}
              <ChipCloudSelection 
                name='availability' 
                label='Availability'
                options={availability} 
                selected={form.availability} 
                handleSelectionChange={handleAvailabilitySelectionChange}
                key_name="key"
                value_name="val"
              />
            </Box>

          </Box>
        </Card>


        <Card sx={{mt: 2}}>

          <Box sx={{pt:2, pb:2, pr:3, pl:3}}>
            <Box sx={{paddingTop: 2, paddingBottom: 2}}>
              <Typography variant='subtitle2'>Pricing models</Typography>
              <Typography variant='caption' sx={{mb: 1}}>You can create multiple pricing models, each slot is assigned to a pricing model.</Typography>
              {(validation.pricing.dirty && validation.pricing.error)  && <Typography variant='caption' color="error" sx={{mb: 1}} component="div">{validation.pricing.msg}</Typography>}
              <BusinessProductPricings name='pricing' value={form.pricing} handleSelectionChange={handleSelectionChange} />
            </Box>
          </Box>

        </Card>


        <Card sx={{mt: 2}}>

          <Box sx={{pt:2, pb:2, pr:3, pl:3}}>

            <Box sx={{paddingTop: 2, paddingBottom: 2}}>
              <Typography variant='subtitle2'>Your slots</Typography>
              <Typography variant='caption' sx={{mb: 1}}>Slots are your individully bookable items, e.g. number of kennels or time availability?</Typography>
              {(validation.slots.dirty && validation.slots.error)  && <Typography variant='caption' color="error" sx={{mb: 1}} component="div">{validation.slots.msg}</Typography>}
              <BusinessProductSlots name='slots' value={form.slots} pricing={form.pricing} handleSelectionChange={handleSelectionChange} />
            </Box>

          </Box>

        </Card>




        <Card sx={{mt: 2, mb: 3}}>

          <Box sx={{pt:2, pb:2, pr:3, pl:3}}>

            <Box sx={{paddingTop: 2, paddingBottom: 2, display: 'flex', gap: 2}}>
              <Box sx={{flex: 1}}>

              <Typography variant='subtitle2'>{validation.uptoDays.display_name}</Typography>
              <Typography variant='caption' sx={{mb: 1}}>How far ahead can I book?</Typography>
              <TextField 
                type="number" 
                name="uptoDays"
                label={null}
                fullWidth 
                value={form.uptoDays} 
                onChange={handleInputChange} 
                error={validation.uptoDays.dirty && validation.uptoDays.error} helperText={validation.uptoDays.dirty && validation.uptoDays.error ? validation.uptoDays.msg : ''}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />

              </Box>



              <Box sx={{flex: 1}}>

                <Box sx={{display: 'flex', alignItems: 'center', gap:1}}>


                <Box sx={{flex: 1}}>
                  <Typography variant='subtitle2' sx={{mb: 1}}>{validation.live.display_name}</Typography>
                  <FormControlLabel control={<Switch color="secondary" checked={form.live} onChange={() => handleSelectionChange('live', !form.live)} />} label={!form.live ? 'No' : 'Yes'} />
                </Box>

                <Box sx={{flex: 1}}>

                  <Typography variant='subtitle2' sx={{mb: 1}}>{validation.public.display_name}</Typography>
                  <FormControlLabel control={<Switch color="secondary" checked={form.public} onChange={() => handleSelectionChange('public', !form.public)} />} label={!form.public ? 'No' : 'Yes'} />

                </Box>
                
                </Box>

              </Box>

              

            </Box>
            
            <Box sx={{paddingTop: 2, paddingBottom: 2, display: 'flex', gap: 2}}>



              <Box sx={{flex: 1}}>

                <Typography variant='subtitle2'>{validation.min.display_name}</Typography>
                <Typography variant='caption' sx={{mb: 1}}>The min days / slots per booking</Typography>
                <TextField 
                  type="number" 
                  name="min"
                  label={null}
                  fullWidth 
                  value={form.min} 
                  onChange={handleInputChange} 
                  error={validation.min.dirty && validation.min.error} helperText={validation.min.dirty && validation.min.error ? validation.min.msg : ''}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />

              </Box>

              <Box sx={{flex: 1}}>

                <Typography variant='subtitle2'>{validation.max.display_name}</Typography>
                <Typography variant='caption' sx={{mb: 1}}>The max days / slots per booking</Typography>
                <TextField 
                  type="number" 
                  name="max"
                  label={null}
                  fullWidth 
                  value={form.max} 
                  onChange={handleInputChange} 
                  error={validation.max.dirty && validation.max.error} helperText={validation.max.dirty && validation.max.error ? validation.max.msg : ''}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />

              </Box>

            </Box>

          </Box>
        </Card>

        
        <Box>
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mb: 3, alignItems: 'center'}}>
              <ProgressButton variant="contained" color="secondary" waiting={waiting} onClick={handleSubmit} gutterBottom={true}><ArrowLabel alt="Create a new calendar with yupeto">{product !== null ? 'Update' : 'Create'}</ArrowLabel></ProgressButton>
            </Box>
        </Box>

      </Box>

    </React.Fragment>
  );
}

export default BusinessProductModify;