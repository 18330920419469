import firebase from 'firebase/app';
import 'firebase/auth';

export function login (payload) {
    return dispatch => {

        dispatch({
            ...payload,
            type: 'USER_LOGIN'
        })

    }
}

export function logout () {
    console.log('logout dispatch');

    return dispatch => {

        firebase.auth().signOut().then(() => {
            // Sign-out successful.
            dispatch({
                type: 'USER_LOGOUT',
                payload: null
            })
          }).catch((error) => {
            // An error happened.
            dispatch({
                type: 'USER_LOGOUT',
                payload: null
            })
          });

    }
}

export function updateProfile (payload) {
    return dispatch => {
        dispatch({
            payload: payload,
            type: 'USER_PROFILE_UPDATE'
        })

    }
}

export function updateProperties (payload) {
    return dispatch => {

        dispatch({
            payload: payload,
            type: 'USER_PROPERTIES_UPDATE'
        })

    }
}