import React, { useContext, useEffect, useState } from 'react';
import {useTheme, Fab} from '@mui/material';
import { makeStyles } from '@mui/styles'
import globalContext from '../../context/globalContext';
import images from '../../assets/imgs/index';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  egg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1
  },
  avatar: {
    display: 'flex',
    width: '76%',
    height: '76%',
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: `2px solid #fff`,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: 2
  },
  image: {
    width: 'auto',
    height: '100%',
  }
}));


const BusinessAvatar = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const size  = parseInt(props.size) || 100;

  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {

    if(context.organisations.activeId !== null){
      const organisation = context.organisations.data.find(o => o.id === context.organisations.activeId);
      setLogoUrl(organisation?.logoUrl);
    }

  }, [context.organisations.activeId])

  return (
    <React.Fragment>
      {context.user.status === 'in' && 
          <div className={classes.wrapper} style={{width: size, height: size}}>
            <img src={images.avatar_egg} alt="Your account" className={classes.egg} />
            <div className={classes.avatar}>
              {!props.children && <img src={logoUrl || images.business_avatar_default} className={classes.image}/>}
              {props.children && props.children}
            </div>

            {typeof props.onEdit ==='function' &&
             <Fab 
              size="small" 
              color="secondary" 
              aria-label="edit" 
              sx={{ 
                position: 'absolute', 
                zIndex: 5, 
                bottom: '10px', 
                right: '10px'}}
              onClick={props.onEdit}
            >
              <EditIcon style={{fontSize: 18}} />
            </Fab>
            }
          </div>
      }
    </React.Fragment>
  );
}

export default BusinessAvatar;
