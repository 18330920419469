import logo from './logo.svg';
import logo_icon from './logo-icon.svg';
import logo_icon_grey from './logo-icon-grey.svg';
import logo_icon_green from './logo-icon-green.svg';
import logo_icon_green_outline from './logo-icon-green-outline.svg';
import bird_house from './bird_house.svg';
import arrow_right from './arrow_right.svg';
import arrow_left from './arrow_left.svg';
import building_utensils from './building_utensils.svg'
import landing_invite from './landing_invite.svg'
import avatar_egg from './avatar_egg.svg'
import avatar_default from './avatar_default.svg'
import guinea_pig from './guinea_pig.svg'
import gecko from './gecko.svg'
import rabbit from './rabbit.svg'
import icon_tick from './icon_tick.svg'
import icon_warning from './icon_warning.svg'
import early_worm from './early_worm.svg'
import whats_the_catch from './whats_the_catch.svg'
import the_gang from './the_gang.svg'
import the_gang_pattern from './the_gang_pattern.png'
import the_gang_small from './the_gang_small.svg'
import business_avatar_default from './business_avatar_default.svg'
import account_pro from './account_pro.svg'
import account_standard from './account_standard.svg'
import dog from './dog.svg'
import cat from './cat.svg'
import bee from './bee.svg'
import fish from './fish.svg'
import listing from './listing.svg'
import zigzag_pattern from './zigzag_pattern.png'
import our_goals from './our_goals.svg';
import our_focus from './our_focus.svg';
import our_origin from './our_origin.svg';
import business_easy from './business_easy.svg';
import introducing_yupeto from './blog/introducing_yupeto.png';
import weekly_roundup from './weekly_roundup.svg';
import dog_hiding from './blog/dog_hiding.png';
import opening_cal from './opening_cal.svg';
import opening_spacer from './opening_spacer.svg';
import opening_overview from  './opening_overview.svg';
import opening_noforms from  './opening_noforms.svg';
import opening_bookings from './opening_bookings.svg';
import why_fits from './why_fits.svg';
import why_notify from './why_notify.svg';
import why_forms from './why_forms.svg';
import why_wallchart from './why_wallchart.svg';
import big_bullet from './big_bullet.svg';
import stripe_blurple from './stripe_blurple.svg';
import card_amex from './card_amex.svg';
import card_visa from './card_visa.svg';
import card_mastercard from './card_mastercard.svg';
import card_any from './card_any.svg';
import icon_rabbit from './icon_rabbit.svg';
import icon_dog from './icon_dog.svg';
import icon_cat from './icon_cat.svg';
import icon_bird from './icon_bird.svg';
import icon_reptile from './icon_reptile.svg';
import icon_small_animal from './icon_small_animal.svg';
import calendars_none from './calendars_none.svg';
import calendar_single from './calendar_single.svg';
import calendar_multiple from './calendar_multiple.svg';
import calendar_period from './calendar_period.svg';
import signin from './signin.svg';
import signin_bg from './signin-bg.svg';
import lines_wiggle from './lines_wiggle.svg';
import lines_dots from './lines_dots.svg';
import lines_zigzag from './lines_zigzag.svg';

const images = {
    logo: logo,
    logo_icon: logo_icon,
    logo_icon_grey: logo_icon_grey,
    logo_icon_green: logo_icon_green,
    logo_icon_green_outline : logo_icon_green_outline,
    bird_house,
    arrow_right,
    arrow_left,
    building_utensils,
    landing_invite,
    avatar_egg,
    avatar_default,
    guinea_pig,
    gecko,
    rabbit,
    icon_tick,
    icon_warning,
    early_worm,
    whats_the_catch,
    the_gang,
    the_gang_small,
    business_avatar_default,
    account_standard,
    account_pro,
    dog,
    cat,
    bee,
    fish,
    the_gang_pattern,
    zigzag_pattern,
    listing,
    our_goals,
    our_focus,
    our_origin,
    business_easy,
    blog: {
        introducing_yupeto
    },
    weekly_roundup,
    dog_hiding,
    opening_cal,
    opening_spacer,
    opening_overview,
    opening_noforms,
    opening_bookings,
    why_fits,
    why_notify,
    why_forms,
    why_wallchart,
    big_bullet,
    stripe_blurple,
    card_amex,
    card_visa,
    card_mastercard,
    card_any,
    icon_rabbit,
    icon_cat,
    icon_dog,
    icon_bird,
    icon_reptile,
    icon_small_animal,
    calendars_none,
    calendar_single,
    calendar_multiple,
    calendar_period,
    signin,
    signin_bg,
    lines_wiggle,
    lines_zigzag,
    lines_dots
}

export default images