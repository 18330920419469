import { Typography, Box, IconButton, Dialog, DialogContent, Button } from "@mui/material"
import React, {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';

const DeleteAndConfirm = props => {

    const [showConfirm, setShowConfirm] = useState(false);

    const handleDeleteItem = msg => {
        setShowConfirm(false);
        if(typeof props.onDelete === 'function')
            props.onDelete();
    }

    return (
        <React.Fragment>
            <IconButton size="small" onClick={() => setShowConfirm(true)} color="secondary"><CloseIcon sx={{fontSize: 18}} /></IconButton>
            <Dialog open={showConfirm} fullWidth maxWidth="xs" onClose={() => setShowConfirm(false)}>
                <DialogContent>
                    <Typography variant="h6">Confirm Delete</Typography>
                    <Typography variant="body2">{props.msg || 'Are you sure you wish to delete this item?'}</Typography>
                    <Box sx={{display: 'flex', flexDirection:'row', justifyContent: 'space-between', alignItems: 'center', mt: 3}}>
                        <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
                        <Button onClick={handleDeleteItem} variant="contained" color="secondary">Delete</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default DeleteAndConfirm;