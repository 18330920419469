  
const organisationsReducer = (state = {
    data: [],
    activeId: null,
    activeSubscription: null,
    status: 'LOADING',
    msg: '',
    pendingOrganisation: null
}, action) => {
    switch(action.type){
        case "ORGANISATIONS_LOAD":
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'ERROR'?action.payload:'',
                data: action.status === 'OK'?action.payload:[]
            }
            break;
        case "ORGANISATION_ACTIVE":
            state = {
                ...state,
                activeId: action.payload
            }
            break;
        case "ORGANISATION_SUBSCRIPTION":
            state = {
                ...state,
                activeSubscription: action.payload
            }
            break;
        case "ORGANISATION_PENDING":
            state = {
                ...state,
                pendingOrganisation: action.payload
            }
            break;
        default:
    }
    return state;
}

export default organisationsReducer;