import { useTheme, Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, {useContext, useState} from "react";
import globalContext from "../../context/globalContext";
import ProfileEmailVerified from "./profileEmailVerified";
import ProfileUploadImage from "./profileUploadImage";
import EditableSingleField from "../../components/editableSingleField";
import ProfileEditName from "./profileEditName";
import ProfileEditEmail from "./profileEditEmail";
import ProfileChangePassword from "./profileChangePassword";
import AlertStack from "../../components/alertStack";

const useStyles = makeStyles((theme) => ({
}))

let alertTimeout;

const ProfileEdit = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [positiveAlerts, setPositiveAlerts] = useState([]);

    const addPositiveAlert = msg => {
      let newAlerts = JSON.parse(JSON.stringify(positiveAlerts));
      newAlerts.push(msg);
      setPositiveAlerts(newAlerts);
      if(typeof alertTimeout !== 'undefined')
        clearTimeout(alertTimeout);

      alertTimeout = setTimeout(handlePopAlert, 4000);
    }

    const handlePopAlert = () => {
      let newAlerts = JSON.parse(JSON.stringify(positiveAlerts));
      newAlerts.splice(0, 1);
      setPositiveAlerts(newAlerts);
      if(newAlerts.length > 0)
        alertTimeout = setTimeout(handlePopAlert, 5000);
    }

    return (
      <React.Fragment>
        <Box className="flex-col flex pb">
            
            <ProfileUploadImage />
            <ProfileEmailVerified />
            <AlertStack severity="success" alerts={positiveAlerts} />

            <Box>
              <EditableSingleField value={context.user.profile.displayName} label="Your Name" onSuccess={addPositiveAlert}>
                <ProfileEditName />
              </EditableSingleField>

              <EditableSingleField value={context.user.profile.email} label="Email" onSuccess={addPositiveAlert}>
                <ProfileEditEmail />
              </EditableSingleField>

              <EditableSingleField value={'**********'} label="Password" onSuccess={addPositiveAlert}>
                <ProfileChangePassword />
              </EditableSingleField>
            </Box>
        </Box>
      </React.Fragment>
    )
}

export default ProfileEdit;