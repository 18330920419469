import { Box, Dialog, DialogContent, IconButton, Typography, TextField, Button, Chip, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, {useState} from "react"
import PencilIcon from '@mui/icons-material/Edit'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DeleteAndConfirm from "../../../components/deleteAndConfirm";

const BusinessProductSlot = props => {

    const [showEdit, setShowEdit] = useState(null)

    const handleInputChange = e => {
        let newEdit = JSON.parse(JSON.stringify(showEdit));
        newEdit[e.target.name] = e.target.value;
        setShowEdit(newEdit)
    }

    const handleSave = () => {

        if(typeof props.handleUpdate === 'function' && typeof props.idx !== 'undefined'){
            props.handleUpdate(props.idx, showEdit);
        }

        setShowEdit(null)

    }

    const handleDelete = () => {

        if(typeof props.handleDelete === 'function' && typeof props.idx !== 'undefined')
            props.handleDelete(props.idx);

        setShowEdit(null)

    }

    const getPricing = () => {
        const idx = props.pricing.findIndex(p => p.id === props.slot.id_pricing);
        return idx === -1 ? 'No pricing' : `${props.pricing[idx].name} (${props.pricing[idx].id})`;
    }

    return (
        <React.Fragment>

            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', backgroundColor: 'grey.300', borderRadius: '8px', padding: '8px 16px'}}>
                <DragIndicatorIcon sx={{fontSize: 22, color: 'grey.500'}} />
                <Box sx={{flex: 1, display: 'flex', gap: 1, alignItems: 'center',}}>
                    <Typography variant="body2">{props.slot.description}</Typography>
                </Box>
                <Typography variant="caption" color="grey.800">{getPricing()}</Typography>
                <Chip color="primary" label={'x'+props.slot.qty} size="small" />
                <IconButton size="small" onClick={() => setShowEdit(props.slot)} color="secondary"><PencilIcon sx={{fontSize: 18}} /></IconButton>
                <DeleteAndConfirm msg='Are you sure you wish to delete this slot?' onDelete={handleDelete} />
            </Box>

            {showEdit !== null && 
                <Dialog open={true} onClose={() => setShowEdit(null)} fullWidth maxWidth="xs">
                    <DialogContent>
                        <Typography variant="h6">Edit slot</Typography>

                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <TextField 
                                type="text" 
                                name="description"
                                label="Slot description"
                                fullWidth 
                                value={showEdit.description} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>

                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <TextField 
                                type="number" 
                                name="qty"
                                label="Qty"
                                fullWidth 
                                value={showEdit.qty} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>

                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <FormControl fullWidth variant="standard">
                                <InputLabel id="pricing-select-label">Pricing</InputLabel>
                                <Select
                                    labelId="pricing-select-label"
                                    name="id_pricing"
                                    value={showEdit.id_pricing}
                                    label="Pricing"
                                    onChange={handleInputChange}
                                >
                                    {props.pricing.map((p, idx) => (<MenuItem key={idx} value={p.id}>{p.name} ({p.id})</MenuItem>))}
                                </Select>
                            </FormControl>

                        </Box>

                        <Button variant="contained" color="secondary" onClick={handleSave}>Save</Button>
                    </DialogContent>
                </Dialog>
            }

        </React.Fragment>
    )
}

export default BusinessProductSlot