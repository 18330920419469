const defaultFieldValidationObject = {
    dirty: false,
    error: false,
    required: false,
    msg: '',
    display_name: '',
    multiline: false,
    rows: 1,
    additional: []
};

export function makeValidation (fields) {
    let validation = {};
    fields.map(field => {
        let _validation = JSON.parse(JSON.stringify(defaultFieldValidationObject));
        _validation.required = field.required || false;
        _validation.display_name = field.display_name || field.name;
        _validation.multiline = field.multiline || false;
        _validation.rows = field.rows || 1;
        _validation.additional = field.additional || [];
        validation[field.name] = _validation;
    })
    return validation;
}

export function validateForm (form, validationObj, dirtyAll = false) {
    
    // Whip up a copy so references don't get involved
    let nvo = JSON.parse(JSON.stringify(validationObj));

    Object.keys(validationObj).map(f => {
        if(typeof form[f] === 'undefined')
            form[f] = '';

        nvo[f].error = false;
        nvo[f].msg = '';
        nvo[f].dirty = dirtyAll || (form[f] !== null && form[f].length > 0) ? true : validationObj[f].dirty;
    
        if(nvo[f].required && form[f].length <= 0){
            nvo[f].error = true;
            nvo[f].msg = `${nvo[f].display_name} is required.`
        }

        if(nvo[f].additional.length > 0){

            nvo[f].additional.map(val => {

                switch(val.type.toUpperCase()){
                    case 'MATCH':
                        if(form[f] !== form[val.field]){
                            nvo[f].error = true;
                            nvo[f].msg = val.msg
                        }
                        break;
                    case 'MIN':
                        if(+form[f] < +val.value){
                            nvo[f].error = true;
                            nvo[f].msg = val.msg
                        }
                        break;
                    case 'MAX':
                        if(+form[f] > +val.value){
                            nvo[f].error = true;
                            nvo[f].msg = val.msg
                        }
                        break;
                    default:
                }

            })

        }
    });

    return nvo;
}

export function isValid (validationObj) {

    let valid = true;
    Object.keys(validationObj).map(f => {
        if(validationObj[f].error)
            valid = false;
    });
    return valid;

}