import { useTheme, IconButton, Typography, Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) => ({
    stackPanelWrapperIn: {
        display: 'flex',
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 999,
        backgroundColor: '#ffffff',
        overflowX: 'hidden',
        overflowY: 'auto',
        transform: 'translateX(0)',
        right: 0,
        top: 0,
        transition: 'all .3s ease',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    stackPannelWrapperOut: {
        display: 'flex',
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 999,
        backgroundColor: '#ffffff',
        overflowX: 'hidden',
        overflowY: 'auto',
        transform: 'translateX(100%)',
        visibility: 'hidden',
        right: 0,
        top: 0,
        transition: 'all .3s ease',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inner: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '86%',
      paddingTop: 30
    }
}))

const StackSlidePanel = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [show, setShow] = useState(false);
    const [render, setRender] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleTransitionEnd = () => {
        if(show === false){
            setRender(false);
            if(typeof props.handleClose === 'function')
                props.handleClose();
        }
    }

    useEffect(() => {
        setShow(props.show);
        setRender(props.show);
    }, [props.show])

    return (
        <div 
        className={show ? classes.stackPanelWrapperIn : classes.stackPannelWrapperOut}
        onTransitionEnd={handleTransitionEnd}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', flex: 1, padding: 1, alignItems: 'center'}}>
                    <IconButton className={classes.close} onClick={handleClose}><ArrowBackIcon /></IconButton>
                    {props.title && <Typography variant="subtitle1">{props.title}</Typography>}
                </Box>
            </Box>
           
            <div className={classes.inner}>
                {render && props.children}
            </div>
        </div>
    )
}

export default StackSlidePanel