import React from 'react';
import {Box, Typography} from '@mui/material';
import images from '../assets/imgs';


const ChipCloudSelection = props => {

    const k = props.key_name || null;
    const v = props.value_name || null;
    const i = props.icon_name || null;
    const mode = (props.mode && props.mode.toUpperCase() === 'SINGLE') ? 'SINGLE' : 'MULTIPLE';

    const toggleSelection = (val) => {
        if(mode === 'SINGLE'){

            if(typeof props.handleSelectionChange === 'function')
                props.handleSelectionChange(props.name, val);

        } else {

            let newSelections = JSON.parse(JSON.stringify(props.selected));
            const idx = newSelections.indexOf(val);
            if(idx > -1){
                newSelections.splice(idx, 1);
            } else
                newSelections.push(val);

            if(typeof props.handleSelectionChange === 'function')
                props.handleSelectionChange(props.name, newSelections);

        }
    }

    const isSelected = val => {
        if(mode === 'SINGLE')
            return k ? props.selected === val[k] : props.selected === val;
        else { 
            const idx = k ? props.selected.indexOf(val[k]) : props.selected.indexOf(val);
            return idx > -1;
        }
    }

    const sx = {
        container: {
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            paddingTop: 1, 
            paddingBottom: 1
        },
        chip: {
            paddingLeft: 2, 
            paddingRight: 2,
            paddingTop: 1,
            paddingBottom: 1, 
            backgroundColor: 'secondary.superLight', 
            color: 'text.primary', 
            borderRadius: 3, 
            cursor: 'pointer',
            transition: 'all ease .3s',
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'center'
        },
        chipSelected: {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText'
        },
        chipIcon: {
            height: '28px',
            width: 'auto'
        },
        chipText: {
            lineHeight: '28px'
        }
    }

    return (

        <React.Fragment>  
            <Box sx={sx.container}>
                {props.options.map((val, idx) => (
                    <Box key={idx} onClick={() => toggleSelection(k ? val[k] : val)} sx={{...sx.chip, ...(isSelected(val) ? sx.chipSelected : {})}}>
                        {(i && val[i]) && <img src={images[val[i]]} style={sx.chipIcon} alt={v? val[v] : val} />}
                        <Typography variant="body2" component="span" sx={sx.chipText}>{v? val[v] : val}</Typography>
                    </Box>
                ))}
            </Box>
        </React.Fragment>
    

    );
}

export default ChipCloudSelection