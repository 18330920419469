import { Box, Drawer, ListItemButton, Typography, styled, List, Link } from '@mui/material';
import React, {useContext} from 'react';
import globalContext from '../../context/globalContext';
import BusinessAvatar from './businessAvatar';
import { useHistory } from 'react-router-dom';
import Images from '../../assets/imgs';


const MainNav = styled(List)(({ theme }) => ({
    paddingTop: 20,
    paddingBottom: 20,
    '& .MuiListItemButton-root': {
      paddingLeft: 16,
      paddingRight: 16,
      fontWeight: 400,
      '&:hover': {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent'
      }
    },
}));

const BusinessMenu = props => {

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const context = useContext(globalContext);
    const history = useHistory();

    const activeId = context.organisations.activeId !== null ? context.organisations.activeId : context.organisations.data.length > 0 ? context.organisations.data[0]?.id : null;

    const onChangeRoute = route => {
        props.handleDrawerToggle();
        history.push(route);
    } 

    const contents = (

        <Box sx={{padding: 2, flex: 1, display: 'flex', flexDirection: 'column'}}>

            <Link href="/"><img src={Images.logo} style={{height: 40}} /></Link>

            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 3, marginBottom: 3}}>
                <BusinessAvatar size={200} />
            </Box>

            <MainNav>
                <ListItemButton onClick={() => onChangeRoute(`/business/account/${activeId}`)} >Dashboard</ListItemButton>
                <ListItemButton onClick={() => onChangeRoute(`/business/account/wallchart/${activeId}`)} >Wallchart</ListItemButton>
                <ListItemButton onClick={() => onChangeRoute(`/business/account/bookings/${activeId}`)} >Bookings</ListItemButton>
                <ListItemButton onClick={() => onChangeRoute(`/business/account/services/${activeId}`)} >Services</ListItemButton>
                <ListItemButton onClick={() => onChangeRoute(`/business/account/profile/${activeId}`)}>Profile</ListItemButton>
                <ListItemButton onClick={() => onChangeRoute(`/business/account/manage/${activeId}`)}>Manage Account</ListItemButton>
            </MainNav>

            <Box sx={{flex: 1}}></Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="caption">v0.13 (beta)</Typography>
                <Box>
                    <Link href="/policies/terms" color="secondary" target="_blank" variant="caption">Terms</Link>
                    <Typography variant="caption"> | </Typography>
                    <Link href="/policies/privacy" color="secondary" target="_blank" variant="caption">Privacy</Link>
                </Box>
            </Box>

        </Box>

    );

    return (
        <React.Fragment>

            <Box
                component="nav"
                sx={{ width: { lg: props.drawerWidth }, flexShrink: { lg: 0 } }}
                aria-label="mailbox folders"
            >

                {/* Slide Out Drawer */}
                <Drawer
                anchor="left"
                container={container}
                variant="temporary"
                open={props.mobileOpen}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', lg: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
                >
                    {contents}
                </Drawer>

                {/* Fixed Drawer */}
                <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', lg: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
                open
                >
                    {contents}
                </Drawer>

            </Box>

        </React.Fragment>
    )
}

export default BusinessMenu;