import { Box, Dialog, DialogContent, IconButton, Typography, TextField, Button, Chip } from "@mui/material";
import React, {useState} from "react"
import PencilIcon from '@mui/icons-material/Edit'
import BusinessProductPrice from './businessProductPrice'
import DeleteAndConfirm from "../../../components/deleteAndConfirm";

const BusinessProductPricing = props => {

    const [showEdit, setShowEdit] = useState(null)

    const handleInputChange = e => {
        let newEdit = JSON.parse(JSON.stringify(showEdit));
        newEdit[e.target.name] = e.target.value;
        setShowEdit(newEdit)
    }

    const handleSave = () => {

        if(typeof props.handleUpdate === 'function' && typeof props.idx !== 'undefined')
            props.handleUpdate(props.idx, showEdit);

        setShowEdit(null)

    }

    const handleDeletePricing = () => {

        if(typeof props.handleDelete === 'function' && typeof props.idx !== 'undefined')
            props.handleDelete(props.idx);

        setShowEdit(null)

    }

    const handleAddPrice = () => {
        let newItem = JSON.parse(JSON.stringify(props.item));
        newItem.prices.push({
            description: 'New price',
            qty: 1,
            price: "10.99"
        });
        if(typeof props.handleUpdate === 'function')
            props.handleUpdate(props.idx, newItem);
    }

    const handleUpdatePrice = (idx, val) => {
        let newItem = JSON.parse(JSON.stringify(props.item));
        newItem.prices[idx] = val;

        if(typeof props.handleUpdate === 'function' && typeof props.idx !== 'undefined')
            props.handleUpdate(props.idx, newItem);
    }

    const handleDeletePrice = (idx) => {
        let newItem = JSON.parse(JSON.stringify(props.item));
        newItem.prices.splice(idx, 1);
        if(typeof props.handleUpdate === 'function' && typeof props.idx !== 'undefined')
            props.handleUpdate(props.idx, newItem);
    }

    return (
        <React.Fragment>

            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', backgroundColor: 'grey.300', borderRadius: '8px', padding: '8px 16px'}}>
                <Box sx={{flex: 1, display: 'flex', gap: 1, alignItems: 'center',}}>
                    <Typography variant="body2">{props.item.name}</Typography>
                </Box>
                <Typography variant="caption" color="grey.800">{props.item.id}</Typography>
                <IconButton size="small" onClick={() => setShowEdit(props.item)} color="secondary"><PencilIcon sx={{fontSize: 18}} /></IconButton>
                <DeleteAndConfirm msg='Are you sure you wish to delete this pricing?' onDelete={handleDeletePricing} />
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, pl: 3}}>
                {props.item.prices.map((item, idx) => (
                    <BusinessProductPrice item={item} key={idx} idx={idx} handleUpdate={handleUpdatePrice} handleDelete={handleDeletePrice} currency={props.item.currency} />
                ))}
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>  
                <Button size="small" color="secondary" onClick={handleAddPrice} variant="contained">Add A Price</Button>
            </Box>

            {showEdit !== null && 
                <Dialog open={true} onClose={() => setShowEdit(null)} fullWidth maxWidth="xs">
                    <DialogContent>
                        <Typography variant="h6">Edit Pricing</Typography>

                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <TextField 
                                type="text" 
                                name="name"
                                label="Pricing name"
                                fullWidth 
                                value={showEdit.name} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>
                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>


                            <TextField 
                                type="text" 
                                name="currency"
                                label="Currency"
                                fullWidth 
                                value={showEdit.currency} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>

                        <Button variant="contained" color="secondary" onClick={handleSave}>Save</Button>
                    </DialogContent>
                </Dialog>
            }

        </React.Fragment>
    )
}

export default BusinessProductPricing