import { Box, Dialog, DialogContent, IconButton, Typography, TextField, Button, Chip } from "@mui/material";
import React, {useState} from "react"
import PencilIcon from '@mui/icons-material/Edit'
import DeleteAndConfirm from "../../../components/deleteAndConfirm";

const BusinessProductPrice = props => {

    const [showEdit, setShowEdit] = useState(null)

    const handleInputChange = e => {
        let newEdit = JSON.parse(JSON.stringify(showEdit));
        newEdit[e.target.name] = e.target.value;
        setShowEdit(newEdit)
    }

    const handleSave = () => {

        if(typeof props.handleUpdate === 'function' && typeof props.idx !== 'undefined')
            props.handleUpdate(props.idx, showEdit);

        setShowEdit(null)

    }

    const handleDelete = () => {

        if(typeof props.handleDelete === 'function' && typeof props.idx !== 'undefined')
            props.handleDelete(props.idx);

        setShowEdit(null)

    }

    return (
        <React.Fragment>

            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', backgroundColor: 'grey.300', borderRadius: '8px', padding: '8px 16px'}}>
                <Box sx={{flex: 1, display: 'flex', gap: 1, alignItems: 'center',}}>
                    <Typography variant="body2">{props.item.description}</Typography>
                </Box>
                <Typography variant="body2">{(+props.item.price).toLocaleString(navigator.language, { style: 'currency', currency: props.currency})}</Typography>
                <Chip color="primary" label={'x'+props.item.qty} size="small" />
                <IconButton size="small" onClick={() => setShowEdit(props.item)} color="secondary"><PencilIcon sx={{fontSize: 18}} /></IconButton>
                <DeleteAndConfirm msg='Are you sure you wish to delete this price?' onDelete={handleDelete} />
            </Box>

            {showEdit !== null && 
                <Dialog open={true} onClose={() => setShowEdit(null)} fullWidth maxWidth="xs">
                    <DialogContent>
                        <Typography variant="h6">Edit Pricing</Typography>

                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <TextField 
                                type="text" 
                                name="description"
                                label="Price description"
                                fullWidth 
                                value={showEdit.description} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>



                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <TextField 
                                type="number" 
                                name="qty"
                                label="Qty"
                                fullWidth 
                                value={showEdit.qty} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>



                        <Box sx={{paddingTop: 1, paddingBottom: 2}}>

                            <TextField 
                                type="number" 
                                name="price"
                                label="Price"
                                fullWidth 
                                value={showEdit.price} 
                                onChange={handleInputChange} 
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />

                        </Box>

                        <Button variant="contained" color="secondary" onClick={handleSave}>Save</Button>
                    </DialogContent>
                </Dialog>
            }

        </React.Fragment>
    )
}

export default BusinessProductPrice