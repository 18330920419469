/**
 * Example
 */
/*
    {
      "name": "", -- The public name of the product e.g. "Small Animal Boarding"
      "type": "single, multiple, period", -- The method you wish to use for the product
      "min": 1, -- The minimum number of slots that must be selected for a booking
      "max": null, -- The maximum number of slots that must be selected for a booking
      "uptoDays": 365, -- The maximum number of days ahead that can be booked
      "slots": [ -- What slots you have available for each day
        {
          "description": "", -- Describe the slot e.g. "Hutch One" or "8:30am"
          "pricing_id": "F79A9E0B", -- The pricing id to abide to
          "qty": 5 -- How many of this slot do you have
        }
      ],
      "pricing": [  -- Pricing models that can be applied to slots
        {
          "id": F79A9E0B,
          "name": "", -- Describe the price e.g. "Occupancy" or "Wash and cut"
          "prices": [
            {
              "description": "Single Occupancy",
              "qty": 1,
              "price": "9",
              "currency": "GBP",
              "type": "small"
            },
            {
              "description": "Double Occupancy",
              "qty": 2,
              "price": "14",
              "currency": "GBP",
              "type": "small"
            }
          ]
        }
      ],
      "availability": ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
    }
*/

export const availability = [
  {key: 1, val: 'Mon'}, 
  {key: 2, val: 'Tue'}, 
  {key: 3, val: 'Wed'}, 
  {key: 4, val: 'Thu'}, 
  {key: 5, val: 'Fri'}, 
  {key: 6, val: 'Sat'}, 
  {key: 0, val: 'Sun'}
];


export const types = [
  {key: 'period', val: 'Period'}, 
  {key: 'multiple', val: 'Multiple'}, 
  {key: 'single', val: 'Single'}
]
  
  
const defaultProduct = {
  "name": "",
  "type": "",
  "min": "1",
  "max": "10",
  "uptoDays": "182",
  "slots": [],
  "pricing": [],
  "availability": [],
  "live": false,
  "public": false
}

export const periodic = {
  ...defaultProduct,
  "name": "",
  "type": "period",
  "min": 1,
  "max": 30,
  "uptoDays": 270
}
  
export const multiple = {
  ...defaultProduct,
  "name": "",
  "type": "multiple",
  "min": 1,
  "uptoDays": 90
}
  
    
export const single = {
  ...defaultProduct,
  "name": "",
  "type": "single",
  "min": 1,
  "max": 1,
  "uptoDays": 60
}