import { Typography, Box, InputLabel, IconButton } from "@mui/material"
import React, {useState} from "react";
import EditIcon from '@mui/icons-material/Edit';

const EditableSingleField = props => {

    const sx = {
        container: {
            padding: '6px 0 10px 0;',
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'center'
        },
        wrapper: {
            flex: 1,
            display: 'inline-flex',
            flexDirection: 'column',
            position: 'relative',
            minWidth: 0,
            padding: 0,
            margin: 0,
            border: 0,
            verticalAlign: 'top',
            width: '100%',
        },
        placeholderWrapper: {
            marginTop: 2
        },
        placeholder: {
            padding: '4px 0 5px',
            lineHeight: 1.4375
        },
        placeholderLabel: {
            position: 'absolute',
            left: 0,
            top: 0,
        },
        icon: {
            fontSize: 24
        }
    }

    const [editMode, setEditMode] = useState(false);
    const {label} = props;

    const handleSuccess = msg => {
        setEditMode(false);
        if(typeof props.onSuccess === 'function')
            props.onSuccess(msg);
    }

    return (
        <React.Fragment>
            {/* <Collapse in={editMode}> */}
                {editMode && React.cloneElement(props.children, {onClose: () => setEditMode(false), onSuccess: handleSuccess})}
            {/* </Collapse>
            <Collapse in={!editMode}> */}
            {!editMode &&
                <Box sx={sx.container}>
                    <Box sx={sx.wrapper}>
                        <InputLabel shrink={true} sx={sx.placeholderLabel}>{label}</InputLabel>
                        <Box sx={sx.placeholderWrapper}>
                            <Typography variant="body1" sx={sx.placeholder}>{props.value || ''}</Typography>
                        </Box>
                    </Box>
                    <IconButton size="small" color="secondary" onClick={() => setEditMode(true)}><EditIcon sx={sx.icon} /></IconButton>
                </Box>
            }
            {/* </Collapse> */}
        </React.Fragment>
    )
}

export default EditableSingleField;