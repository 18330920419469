import * as React from 'react';
import { useTheme, Backdrop, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    pawWrapper: {
        position: 'relative',
        paddingTop: 6,
        paddingBottom: 6,
        width: 100,
        height: 100
    },
    paw: {
        position: 'absolute',
        top: 20,
        left: 20,
        width: 60,
        height: 60,
        animation: `$pawLoaderAnimation 1000ms infinite ${theme.transitions.easing.easeInOut}`
    },
    pawIcon: {
        height: '100%',
        width: '100%'
    },
    "@keyframes pawLoaderAnimation": {
        "0%": {
            opacity: .7,
            transform: "scale(1)"
        },
        "50%": {
            opacity: 1,
            transform: "scale(1.2)"
        },
        "100%": {
            opacity: .7,
            transform: "scale(1)"
        }
    }
}))

const YupetoFullLoader = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

  return (
      <React.Fragment>
        <Backdrop
            sx={{ backgroundColor: 'rgba(255, 255, 255, .6)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            

            <Box className={classes.pawWrapper}>

                {/* <CircularProgress size={100} sx={{position: 'absolute', left: 0, top: 0}} /> */}

            <div className={classes.paw}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 230 205.81" className={classes.pawIcon}>
                    <defs>
                        <clipPath id="clip-Artboard_1">
                        <rect width="230" height="205.81"/>
                        </clipPath>
                    </defs>
                    <g id="Artboard_1" data-name="Artboard 1" clipPath="url(#clip-Artboard_1)">
                        <g id="Group_4" data-name="Group 4" transform="translate(-101.401 -115.905)">
                            <path id="Path_15" data-name="Path 15" d="M101.56,242.014c-3.385,9.174,48.252,45.94,61.014,47.627s26.163-35.232,15.851-42.946C167.6,238.595,104.949,232.838,101.56,242.014Z" transform="translate(0 -44.555)" fill="#FF5F5D"/>
                        </g>
                    </g>
                </svg>
            </div>

                <div className={classes.paw} style={{animationDelay: '100ms'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 230 205.81" className={classes.pawIcon}>
                        <defs>
                            <clipPath id="clip-Artboard_1">
                            <rect width="230" height="205.81"/>
                            </clipPath>
                        </defs>
                        <g id="Artboard_1" data-name="Artboard 1" clipPath="url(#clip-Artboard_1)">
                            <g id="Group_4" data-name="Group 4" transform="translate(-101.401 -115.905)">
                                <path id="Path_13" data-name="Path 13" d="M268.931,115.9c9.78,0,26.39,61.172,23.554,73.731s-42.115,12.345-45.779,0C242.858,176.673,259.152,115.9,268.931,115.9Z" transform="translate(-53.188)" fill="#FF5F5D"/>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className={classes.paw} style={{animationDelay: '200ms'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 230 205.81" className={classes.pawIcon}>
                        <defs>
                            <clipPath id="clip-Artboard_1">
                            <rect width="230" height="205.81"/>
                            </clipPath>
                        </defs>
                        <g id="Artboard_1" data-name="Artboard 1" clipPath="url(#clip-Artboard_1)">
                            <g id="Group_4" data-name="Group 4" transform="translate(-101.401 -115.905)">
                                <path id="Path_14" data-name="Path 14" d="M417.328,240.393c3.385,9.174-48.251,45.938-61.014,47.626s-26.164-35.232-15.851-42.945C351.294,236.974,413.94,231.217,417.328,240.393Z" transform="translate(-86.086 -43.96)" fill="#FF5F5D"/>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className={classes.paw} style={{animationDelay: '300ms'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 230 205.81" className={classes.pawIcon}>
                        <defs>
                            <clipPath id="clip-Artboard_1">
                            <rect width="230" height="205.81"/>
                            </clipPath>
                        </defs>
                        <g id="Artboard_1" data-name="Artboard 1" clipPath="url(#clip-Artboard_1)">
                            <g id="Group_4" data-name="Group 4" transform="translate(-101.401 -115.905)">
                                <path id="Path_16" data-name="Path 16" d="M309.578,382.063c-9.757,7.973-47.053-24.9-63.866-24.9-17.713,0-54.11,32.877-63.867,24.9s7.285-40.917,19.366-54.286,19.877-30.982,25.546-37.141c5.67-6.141,18.955-5.185,18.955-5.185s13.283-.956,18.954,5.185c5.671,6.158,13.464,23.773,25.546,37.141S319.334,374.091,309.578,382.063Z" transform="translate(-29.099 -61.593)" fill="#FF5F5D"/>
                            </g>
                        </g>
                    </svg>
                </div>
            
            </Box>

        </Backdrop>
      </React.Fragment>
  );
}

export default YupetoFullLoader;