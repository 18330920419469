import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';
import {Drawer, Box, Avatar, IconButton, List, useTheme, Typography, styled, ListItemButton, Divider, ListItemText} from '@mui/material';
import {makeStyles} from '@mui/styles';
import globalContext from '../context/globalContext';
import CloseIcon from '@mui/icons-material/Close';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Profile from './profile';
import { useHistory } from 'react-router-dom';
import images from '../assets/imgs';




const useStyles = makeStyles(theme => ({
  socialIcon: {
    color: theme.palette.secondary.main
  }
}))


const MainNav = styled(List)(({ theme }) => ({
  paddingTop: 20,
  paddingBottom: 20,
  '& .MuiListItemButton-root': {
    paddingLeft: 16,
    paddingRight: 16,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    }
  },
}));


const MainMenu = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  const history = useHistory();


  const handleSwitchOrganisation = id => {

    context.organisationActive(id);
    history.push(`/business/account/${id}`);
    context.toggleMenuOpen(false);

}

  return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose={() => context.toggleMenuOpen(false)}
      >
        <Box sx={{padding: 2, minWidth: 320, maxWidth: 500}}>

            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginBottom: 1}}>
                <IconButton onClick={() => context.toggleMenuOpen(false)} size="small"><CloseIcon sx={{fontSize: 22}} /></IconButton>
            </Box>
            {context.user.status === 'in' && <Profile />}

            <Box sx={{padding: 2}}>
              <Typography variant="subtitle2">Switch accounts</Typography>
            </Box>
            <List dense>
                {context.organisations.data.map((o, idx) => (
                    <ListItemButton key={idx} onClick={() => handleSwitchOrganisation(o.id)} selected={o.id === context.organisations.activeId}>
                        <Box sx={{minWidth: '52px'}}>
                            <Avatar size={28}>
                                <img src={o.logoUrl || images.business_avatar_default} style={{width: 'auto', height: '100%'}} />
                            </Avatar>
                        </Box>
                        <ListItemText primary={o.organisation_name} />
                    </ListItemButton>
                ))}
            </List>

            <Divider />
            <MainNav>
              {context.user.status === 'in' && <ListItemButton button onClick={() => {context.toggleMenuOpen(false); context.logout()}}>Sign out</ListItemButton>}
            </MainNav>
            <Divider />
            <Typography variant="caption" component={'div'} align="center" sx={{marginBottom: 1}}>Follow us</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center'}}>
              <a href="https://twitter.com/YupetoHQ" rel="nofollow" target="_blank" className={classes.socialIcon}><TwitterIcon /></a>
              <a href="https://www.instagram.com/yupetohq/" rel="nofollow" target="_blank" className={classes.socialIcon}><InstagramIcon /></a>
            </Box>
        </Box>
    </Drawer>
  );
}

export default withRouter(MainMenu);
